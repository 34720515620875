import axios from 'axios';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useUtils from '../utils/useutiles';

const useApi = () => {
 const history = useHistory()
 const {setToast} = useUtils();

 const requestMaker = async (api, params) => {
 	const form = new FormData();
  const key = Object.keys(params);
  const value = Object.values(params);
  for(var i = 0; i < key.length; i++){
   form.append(key[i], value[i]);
  }
  const request = {
    method: 'post',  
    url: api, 
    headers: { 'Content-Type': 'multipart/form-data'}, 
    data: form,
  }  
  try {
   const status = await axios(request);
   const res = status?.data;
   if(res?.status == 'done'){      // invalid login credenils
    return res; 
   }else if(res?.status == 'exist') {
    setToast('This email has already been registered. Please use another email');
   }else if(res?.status == 'no token') {
    history.push('/adminlogin')
   }else {
    setToast(res?.data);
   }

   if(res?.status == 'expired'){      // invalid login credenils
    return res; 
   }

  }catch (error) {
    if(error.response?.status === 500 ){
      setToast('Server error');
     return;
    }
    if(error.response?.status === 400 ){
     setToast('Internet error. Please try again');
     return;
    }
   if(error.message === 'Network Error' ){
    setToast('Internet error. Please try again');
     return;
   }
  }
}


const getNews = async (category) => {
  const options = {
    method: 'GET',
    url: 'https://real-time-news-data.p.rapidapi.com/search',
    params: {
      query: category,
      country: 'US',
      lang: 'en'
    },
    headers: {
      'X-RapidAPI-Key': 'e42e534b29msh51f682836ca0b76p13d6afjsn4ce507eaa6d4',
      'X-RapidAPI-Host': 'real-time-news-data.p.rapidapi.com'
    }
  };
  try {
    const response = await axios.request(options);
    return response?.data
  } catch (error) {
    console.error(error);
  }
}









 
return {
  requestMaker,
  getNews
 };
}
 
export default useApi;