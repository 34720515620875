import Slider from "react-slick";
import Fade from 'react-reveal/Fade';
import moment from "moment";
import useUtils from "../utils/useutiles";
const Layout = ({news, slidesToShow}) => {
  const now = moment();
  const formattedTime = now.format('YYYY-MM-DD HH:mm:ss');
  const {formatTransactionTime} = useUtils()

 const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: true // prevent pausing on hover
};
 return ( <>
 <Slider  {...settings}>
 {news?.map((i, index) => (
          <div key={index}>
           <Fade bottom> 
          <div className='my-col-12 xs-12   xs-down-5 down-3 c-pointer'  onClick={()=> {window.open(`${i.link}`, "_blank")}}>
            <div className='my-col-11 xs-12 blog-hover'>
              <div className="my-col-10 off-1 xs-10 xs-off-1 xs-down-10 down-3 my-bottom-10">
              <div className='my-mother xs-down-5 down-5'><span className='px13  upper-case monRblack bold'>{i.title}</span></div>
              <div className='my-mother down-5 xs-down-1'><span className='px10 upper-case faded-1'> {formatTransactionTime(i?.published_datetime_utc, new Date())}</span></div>
                {/* <span className="blog-logo"><img src={i.source_logo_url} alt="" /></span> */}
              </div>
            </div>
          </div>
          </Fade>
          </div>
        ))}
 </Slider>
 </> );
}
 
export default Layout;