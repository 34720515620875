import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import OurOffers from "./components/ouroffers";
import ViewProjects from "./components/viewprojects";
import About from "./pages/about";
import Home from "./pages/home";
import NotFound from "./pages/notfound";
import Prices from "./pages/prices";
import Projects from "./pages/projects";
import Events from "./components/events";
import FormData from "./pages/formdata";
import AdminLogin from "./pages/adminlogin";
import AdminHome from "./pages/dashboard/home";
import NewPosts from "./pages/dashboard/addpost";
import AuthContextProvider from "./context/authcontext";
import BlogContextProvider from "./context/blogcontext";
import FrontendContextProvider from "./context/frontendcontext";
import AllBlogs from "./pages/allblogs";
import ViewPost from "./pages/viewpost";
import Messages from "./pages/dashboard/messages";
import Agents from "./pages/dashboard/agents";
import NewProperties from "./pages/dashboard/properties";
import AllProducts from "./pages/dashboard/products";
import ViewProducts from "./pages/viewproduct";
import Gallery from "./pages/gallery";
import NewHome from "./pages/newhome";
import Contact from "./pages/contact";
import AgentSignUp from "./pages/agentsignup";
const App = () => {
  return ( 
    <div>
     <div className="isSending bg-blur" id="my-loader">
      <span><i className="fas fa-spinner fa-pulse"></i></span>
     </div>
     <OurOffers/>
     <div className="my-toast" id="my-toast">
    <div className="xs-container my-col-10">
      <div className="xs-12 pdl-10 down-1"><span id="msg" className="xs-px13"></span></div>
    </div>
   </div>
      <Router>
        <Switch>
        <FrontendContextProvider>
         <Route path="/" exact component={ NewHome }/>
         <Route path="/contact"  component={ Contact }/>
         <Route path="/agents"  component={ AgentSignUp }/>
         <Route path="/blog" component={AllBlogs}/>
         <Route path="/post/:id" component={ViewPost}/>
         <Route path="/product/:id" component={ViewProducts}/>
        <Route path="/offers"  component={ Prices} />
        <Route path="/about"  component={About} />
        <Route path="/projects" exact component={Projects} />
        <Route path="/projects/:id"  component={ViewProjects} />
        <Route path="/onePercent"  component={Events} />
        <Route path="/forms"  component={FormData} />
        <Route path="/adminlogin"  component={AdminLogin} />
        <Route path="/gallery"  component={Gallery} />
        <BlogContextProvider>
          <Route path="/admin/dashboard" exact  component={AllProducts}/>
          <Route path="/admin/blogs"  component={AdminHome}/>
          <Route path="/admin/newpost" component={NewPosts}/>
          <Route path="/admin/messages" component={Messages}/>
          <Route path="/admin/agents" component={Agents}/>
          <Route path="/admin/properties" component={NewProperties}/>
        </BlogContextProvider>
        </FrontendContextProvider>
           <Route path="*" component={ NotFound } />
        </Switch>
      </Router>
   </div>
   );
}
 
export default App;
