import { useState } from "react"
import useUtils from "../utils/useutiles"
import useApi from "../hooks/useApi";
import NewNav from "../components/newNav";

const AgentSignUp = ({setcloseSignUp}) => {
  const {requestMaker} = useApi();
  const [error, setError] = useState(null)
  const [pending, setPending] = useState(false)
  const [params, setparams] = useState({
    name:"",
    email:"",
    phone:"",
    gender:"",
    experienced:"",
    country:"",
    action:"sign_up"
  })
  
  const handleClick =(id) => {
    document.getElementById(id).click()
  }

  const signUpHander = async (e) => {
    e.preventDefault();
    if(params?.name === ""){
      setError('Please enter full name');
      return
    }
    if(params?.email === ""){
      setError('Please enter email address');
      return
    }
    if(params?.phone === ""){
      setError('Please enter Phone Number');
      return
    }
    if(params?.gender === ""){
      setError('Please select gender');
      return
    }
    if(params?.country === ""){
      setError('Please select Country');
      return
    }
    if(params?.experienced === ""){
      setError('Are you an experienced marketer ?');
      return
   }
  setPending(true)
  const res = await requestMaker('agents/signup', params);
  if(res?.status === 'done'){
    alert('We have received your application.  We will reach you as soon as we can. Please check you emails often.... Thanks');
    setPending(false)
    setcloseSignUp(false)
  }else {
    setPending(false)
    // setcloseSignUp(false)
  }
}

 

  return ( <>
  <NewNav/>
  <div className="my-mother down-10 xs-down-10">
    <div className="my-col-6 xs-container xs-down-20 off-3 down- my-bottom-50 rad-10 bg-faded">
     <div className="my-col-10 xs-10 xs-off-1 xs-down-10 off-1 down-3">
      <div className="px20 black"><span className="px20 bottom-bds">Sign</span> Up</div>
      <div className="my-mother down-2 xs-down-10 xs-px13"><span className="black px13" >We will contact you via your email and / or Phone Number.</span></div>
       <div className="my-mother down-2 xs-down-8">
        {error &&  <div className="fade-in c-pointer"><span className="error-msg" onClick={() => {setError(null)}}>{error}</span></div>}
       
        <form onSubmit={(e)=>{signUpHander(e)}} >
          <div><input type="text" onInput={(e) => {setparams(prev => ({...prev, name:e.target.value}))}} className="input monR bg-white" placeholder="Full Name" /></div>
          <div><input type="text" onInput={(e) => {setparams(prev => ({...prev, email:e.target.value}))}} className="input monR bg-white" placeholder="Valid Email" /></div>
          <div><input type="text" onInput={(e) => {setparams(prev => ({...prev, phone:e.target.value}))}} className="input monR bg-white" placeholder="Phone Number" /></div>
          <div>
            <select className="input monR bg-white"  onInput={(e) => {setparams(prev => ({...prev, gender:e.target.value}))}}>
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
          <div>
            <select className="input monR bg-white"  onInput={(e) => {setparams(prev => ({...prev, country:e.target.value}))}}>
              <option value="">Select Country</option>
              <option value="Nigeria">Nigeria</option>
              <option value="USA">USA</option>
              <option value="UK">UK</option>
            </select>
          </div>
          <div className="my-mother down-2 xs-down-5 px13 "><span>Have you any marketing experience ?</span></div>
          <div className="my-col-3 xs-6">
            <div className="my-col-6 xs-6">
            <div className="my-col-5 xs-5">  <span className="px13 ">Yes</span></div>
            <div className="my-col-2 down-5 xs-2"> <input type="radio" onInput={(e) => {setparams(prev => ({...prev, experienced:"Yes"}))}}  name="answer" className="mg-5" /></div>
            </div>
            <div className="my-col-6 xs-6">
            <div className="my-col-5 xs-6">  <span className="px13">No</span></div>
            <div className="my-col-2 down-3 xs-2"> <input type="radio" onInput={(e) => {setparams(prev => ({...prev, experienced:"No"}))}}  name="answer" className="mg-5" /></div>
            </div>
          </div>
          {/* <input type="file" accept=".pdf" hidden  id="file" />
          <div className="my-mother down-5"><span className="cta-1 bg-faded alice px13 c-pointer" onClick={() => {handleClick('file')}}>Upload Cv (PDF Format Only)</span> </div> */}
         
          {!pending?  <div className="my-mother down-10 xs-down-10"><span className="cta-1 n-bg-color-code-1 alice px13 c-pointer" onClick={(e) => {signUpHander(e)}}>Submit</span></div> :  <div className="my-mother down-10"><span className="cta-1 bg-faded alice px13 c-pointer">Submitting ...</span></div>}
        </form>
       </div>
     </div>
    </div>
  </div>

  </>  );
}
 
export default AgentSignUp;