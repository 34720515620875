import moment from "moment";
import { BlogContext } from "../../context/blogcontext";
import useApi from "../../hooks/useApi";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Layout from "./layout";
import {createContext, useEffect, useContext, useState} from 'react';
import useUtils from "../../utils/useutiles";
import CatSkeleton from "../../components/skeleton/catSeleton";

const Messages = () => {
 const {newMessage, getMessage, message} = useContext(BlogContext) 
 const token = localStorage.getItem('fla-admin');
 const path = 'https://encephalic-checkout.000webhostapp.com/images/'
 const {requestMaker} = useApi();
 const history = useHistory();
 const {formatTransactionTime, isSending} = useUtils();
 const now = moment();
 const [edit, setEdit] = useState(null);
//  const [deletePost, setDelete] = useState(null);
 const formattedTime = now.format('YYYY-MM-DD HH:mm:ss');
 const [openMessage, setOpenMessage] = useState(); 
 const [Delete, setDelete] = useState(null); 

 useEffect(() => {
  if(token){
    getMessage(token)
  }else {
    history.push('/admnlogin')
  }
 }, [])

 const deleteMessage = async(id, e) => {
  e.stopPropagation();
  const params = {id, action:"delete_message", token};
  isSending(true)
  const res = await requestMaker('message/messagehandler', params);
  if(res?.status === 'done'){
   await getMessage(token);
   isSending(false)
  }else {
    isSending(false)
  }
 }

 const closeReplied = async(id, e) => {
  const params = {id, action:"update_replied", token};
  isSending(true)
  const res = await requestMaker('message/messagehandler', params);
  if(res?.status === 'done'){
    await getMessage(token);
    isSending(false)
   setOpenMessage(null)
  }else {
    isSending(false)
  }
 }


 return ( <>
  <Layout/>
     <div className="my-col-10 off-1 xs-10 monR xs-left down-10 xs-down-10vh">
     <div className="my-col-10 off-1"> <div className="alice white bold"><span className="bottom-bds">All</span> Messages</div></div>
     <div className="my-col-10 off-1 xs-12 down-5 xs-down-1">
     {message === null && <div className="my-col-12 down-1 xs-12 xs-down-10"><CatSkeleton slideToShow={4} /></div> }
      {message?.map((i) => (
       <> 
       {openMessage == `${i?.id}` &&  
       <div className="my-bg-modal bg-blur">
         <div className="xs-10 xs-off-1 bgrad my-bottom-50 my-col-6 off-3 down-10 xs-down-10">
          <div className="xs-container xs-down-10 my-col-10 off-1 down-5 px13">
          <div className="px13 white bold monR px13"> <i className="fas fa-message"></i> Message</div>
           <div className="my-mother down-2 xs-down-5"><span>Name  <span className="white">{i?.name}</span>  </span></div>
           <div className="my-mother down-2 xs-down-5"><span>Phone Number  <span className="white">{i?.phone}</span>   </span></div>
           <div className="my-mother down-2 xs-down-5"><span>Email Address:  <span className="white">{i?.email}</span>   </span></div>
           <div className="my-mother down-2 xs-down-5"><span>Refered By:  <span className="white">{i?.refered_by}</span>  </span></div>
           <div className="my-mother my-bottom-50 down-2">
            <div><span className="black">Message:</span></div>
            <div className="my-mother down-1"> <span className="px12 faded monR">{i?.message}</span></div>
          </div>
           {i?.replied == 0 && <div className="my-mother down-2 xs-down-4"><span className="cta-1 white px13 bg-black c-pointer" onClick={(e) => {closeReplied(i?.id)}}>Close</span></div> }
           {i?.replied == 1 &&  <div className="my-mother down-2 xs-down-4"><span className="cta-1 white px13 bg-black c-pointer" onClick={(e) => {setOpenMessage(null)}}>Close</span></div>}
          </div>
        </div>
        </div>}

     
        {Delete == `${i.id}` &&   <div className="my-bg-modal">
        <div className="my-col-4 off-4 xs-10 xs-off-1 xs-down-20vh centeed down-15 bg-faded my-bottom-50">
        <div className="col-10 off-1 down-5 xs-10 xs-off-1 xs-down-10">
         <div className="bd-bottom"><span className="bold alice">Delete Message</span></div>
        <div className="my-mother down-3 xs-down-3 faded-2 px12"><span>Are you sure you want to permanently delete this message ?</span></div>
        <div className="my-mother down-6 xs-down-10">
         <span className="pd10 bgrad alice px13 c-pointer" onClick={(e)=> {setDelete(null); e.stopPropagation()}}>No</span>
         <span className="pd10 off-1 xs-off-1 bg-faded alice px13 c-pointer" onClick={(e) =>{deleteMessage(i.id, e)}}>Yes</span>
        </div>
        </div>
       </div>
       </div>}
        <div className="my-col-3 xs-6 xs-down-10 down-2 c-pointer" onClick={(e) => {setOpenMessage(i?.id)}}>
         <div className="my-col-11 xs-11 rad-10 my-bottom-10 bg-faded">
          <div className="my-col-10 off-1 xs-10 xs-off-1 down-5 xs-down-5">
           <span> <i className="fas fa-message color-code-1"></i></span>
           <div><span className="px12 color-code-1">From {i.name?.slice(0, 10) + "..."}</span></div>
           <div className="my-mother top-3"><span className="px10 color-code-1">{formatTransactionTime(i?.trn, formattedTime)}</span></div>
           <div className="my-mother down-2 xs-down-5"><span className="px10 color-code-1 btn-sm bg-black "  onClick={(e) => {setDelete(i.id); ; e.stopPropagation()}} ><i className="fas fa-trash"></i></span></div>
         </div>
       </div>
      </div>
     </>
     ))}   
   </div>
  </div>
  </>);
}
 
export default Messages;