import Nav from "./nav";
import img2 from '../images/sec.jpeg'
import { useEffect } from "react";
import Footer from "./footer";
import { useRef } from "react";
import { useState } from "react";
import axios from "axios";
import useApi from "../hooks/useApi";

const Events = () => {
  const [country, setCountry] = useState([])
  const {requestMaker} = useApi();
  const [isPending, setIspending] = useState(false)
  const divRef = useRef(0)
  const handleClick = () => {
   divRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const [details, setDetails] = useState({
    action:'submit',
    name:"",
    phone:"",
    email:"",
    country:""
  });

  useEffect(() => {
    document.title = 'Invest in the same Assets with the 1%'
  })

  const getCountry = async () => {
   try {
    const res = await axios.get('https://raw.githubusercontent.com/samayo/country-json/master/src/country-by-name.json');
    const data = await res.data;
    setCountry(data)
   } catch (error) {
    setTimeout(() => {
      getCountry();
    }, 2000)
   }
  }

  const submitDetails = async (e) => {
    e.preventDefault();
    setIspending(true)
    const res = await requestMaker('user/aaj', details);
    if(res?.status === 'done') {
      alert("You have successful registered for this event. Please check your mail for feedbacks ...... Thanks");
      setIspending(false)
    }else {
      setIspending(false)
    }
   }
 
  useEffect(() => {
    getCountry();
  }, [])




  const toZoom = (e) => {
   e.preventDefault();
   window.open(`https://us06web.zoom.us/meeting/register/tZYqceGsrj0uH9dVEDtIT9rEkMkUHZXbJqDz`)
  }


  return (<>
   <div className="my-mother my-bottom-50">
   <div className="my-mother"><Nav/></div>
    <div className="my-col-10 off-1 centered xs-10 xs-off-1 down-10 xs-down-15vh">
      <div className="px30 bold centered alice xs-px20"><span className="focus color-code-1">Learn Practical Cashflow Secrets from 1% Individuals</span></div>
      <div className="my-mother bold centered alice down-2 xs-down-3">A Weekly Virtual Conversation</div>
      <div className="my-mother down-5 xs-down-5 xs-down-15">
        <div className="my-mother"><span className="px50 xs-px30 upper-case bold color-code-1"><span className="white">Cashflow</span> Secrets<span className="white">...</span></span></div>
       <div className="my-col-10 off-1 down-1 alice xs-down-5 xs-12 px13">
       You are only safe in today’s economy if you have a job or own a business and both your revenue and profits are growing at a rampant pace.   But don't put your guard down. Anyone can be hit with cash flow problems if their finance, operations, and/or investing activities aren't running efficiently.
        <p className="my-mother down-3 xs-down-5"><span className="px13">
      </span></p>
        </div>
         <div className="my-mother down-3 xs-down-8">
          <span className="register bg-color-code-s1 white bold c-pointer fa-beat-fade" onClick={handleClick}>Click Here to Register</span>
          {/* <span className="register bg-color-code-s1 white bold c-pointer mgl-20">See Advert <i className="fas fa-video"></i></span> */}
        </div>
          <div className="my-mother centered alice down-4 xs-down-8vh"><span>See flyer below for more details</span></div>
         <div className="my-mother down-3 xs-down-5">
        <div className="img-container-2"><img src={img2} alt="" /></div>
       </div> 
       <div className="my-mother down-5 xs-down-15 left" ref={divRef} >
        <div className="my-mother down-1 centered"><span className="white px20 bold upper-case">Registration Form</span></div>
        <div className="my-col-4 off-4">
        <form  onSubmit={(e) => {submitDetails(e)}} >
        <div className="my-col-12 xs-12">
        <div className="my-col-12 xs-12 down-3 xs-down-5 white px13">
          <div className="my-col-12 xs-12 down-3 xs-down-5 white px13">
           <span>Fullname</span>
            <div><input type="text" required onChange={(e) => setDetails(prev => ({...prev, name:e.target.value}))}  className="input" /></div>
          </div>
            <div className="my-col-12 xs-12 down-3 xs-down-5">
            <span>Country</span>
              <select name="" id="" required onChange={(e) => setDetails(prev => ({...prev, country:e.target.value}))} className="input bg-black">
                <option value="">....</option>
                {country?.map((i, index) => (
                <option value={i.country}>{i.country}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="my-col-12 xs-12 down-3 xs-down-5 white px13">
            <span>Email</span>
            <div><input type="email" required onChange={(e) => setDetails(prev => ({...prev, email:e.target.value}))}  className="input" /></div>
          </div>
          <div className="my-col-12 xs-12 down-3 xs-down-5 white px13">
            <span>Phone Number</span>
            <div><input type="tel" required onChange={(e) => setDetails(prev => ({...prev, phone:e.target.value}))}  className="input" /></div>
          </div>
        </div>
        <div className="my-mother down-2 xs-down-10">
          <button className="bg-color-code-s1 my-mother centered white px13 btns" >{isPending ? <i className="fas fa-spinner fa-spin"></i>: 'Register'}</button>
        </div>
        </form>
        </div>
       </div>
      </div>
    </div>
   </div>
   <Footer/>
  </> );
}
 
export default Events;