import { AiOutlineWechat} from 'react-icons/ai'
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useUtils from "../utils/useutiles";
const Nav = ({active}) => {
 const history = useHistory(0)
 const {openModal, closeModal} = useUtils()
 const [navBackground, setNavBackground] = useState(false);
 useEffect(() => {
  const handleScroll = () => {
    const scrollPosition = window.pageYOffset;
    if (scrollPosition > 50) {
      setNavBackground(true);
    } else {
      setNavBackground(false);
    }
  };
  window.addEventListener('scroll', handleScroll);
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);

 return (<>
  {/* <OurOffers/> */}
  <span className="call-us-btn bg-blue b-shadow fa-shake" title="Contact Us" onClick={()=> {openModal('contact-us')}}> <i className='fas fa-message'></i> </span>
 <div className="mobile-nav-full hidden-ls" id="openModal" >
  <div className='xs-7 xs-off-6 xs-down-30 p-absolute opa1'> <span className='my-logo'></span></div>
  {/* <div className="xs-2 right xs-down-10vh faded p-"><span><i className="fas fa-times"></i></span></div> */}
  <span className="my-closebtn" onClick={()=>{closeModal('openModal')}}><i className="fas fa-times color-code-1"></i></span>
  <div className="xs-10  link-con">
   <span className="links color-code-1 xs-down-10vh" onClick={()=> {history.push('/')}}>HOME</span>
   <span className="links color-code-1" onClick={()=> {history.push('/gallery')}}>PROPERTIES</span>
   <span className="links color-code-1" onClick={()=> {history.push('/about')}}>ABOUT</span>
   {/* <span className="links color-code-1" onClick={()=> {history.push('/projects')}}>OUR PROJECTS</span> */}
   <span className="links color-code-1" onClick={()=> {history.push('/blog')}}>BLOG</span>
  </div>
 </div>


 <div className={`${navBackground ? 'mobile-navf bg-black hidden-ls' : 'mobile-navf hidden-ls'}`}>
  <div className='xs-container'>
    <div className="my-logo hidden-ls"></div>
  <div className="xs-container xs-down-4"><i className="pd-30 bg-blue white fl-right fas fa-bars px20" onClick={()=> {openModal('openModal')}}></i></div>
  </div>
 </div>

  <nav  className={`${navBackground ? 'hidden-xs' : 'hidden-xs'}`}>
     <div className="my-logo"></div>
       <span className={`links ${active === 'home' && 'active'}`} onClick={()=> {history.push('/')}}>Home</span>
       <span className={`links ${active ==='gallery' && 'active'}`} onClick={()=> {history.push('/gallery')}}>Properties</span>
       <span className={`links ${active ==='about' && 'active'}`} onClick={()=> {history.push('/about')}}>About</span>
       {/* <span className={`links ${active ==='projects' && 'active'}`} onClick={()=> {history.push('/projects')}}>Projects</span>     */}
       <span className={`links`} onClick={()=> {openModal('contact-us')}} >Contact Us</span>    
      <span className="links down-1" onClick={()=> {history.push('/blog')}} >Blog</span>
    </nav>
 </> );
}
 
export default Nav;