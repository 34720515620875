import {createContext, useEffect, useState} from 'react';
import useApi from '../hooks/useApi';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useUtils from '../utils/useutiles';


export const FrontendContext = createContext();

const FrontendContextProvider = (props) => {
 const path = 'https://encephalic-checkout.000webhostapp.com/images/'
 const {requestMaker} = useApi();
 const {isSending} = useUtils();
 const [blogs, setBlogs] = useState([])
 const [products, setProducts] = useState([])
 const [properties, setProperties] = useState([])
 const [post, setPost] = useState(null)
 const history = useHistory();
 
 const getBlog = async() => {
  const params = {action:'get_all_posts'}
  const result = await requestMaker('blog/frontendhandler', params);
  if(result?.status === 'done'){
   setBlogs(result?.data);
   isSending(false)
  }else {
   isSending(false)
  }
 }

 const getProperties = async() => {
  const params = {action:'get_all_posts'}
  const result = await requestMaker('product/frontendhandler', params);
  if(result?.status === 'done'){
    setProperties(result?.data);
  }else {
   isSending(false)
  }
 }


 const getProduct = async(id) => {
  const params = {action:'get_post_by_id', id}
  isSending(true)
  const result = await requestMaker('product/frontendhandler', params);
  if(result?.status === 'done'){
   setProducts(result?.data);
   isSending(false)
  }else {
   history.push('/gallery')
   isSending(false)
  }
 }

 const getPost = async(id) => {
  const params = {action:'get_post_by_id', id}
  isSending(true)
  const result = await requestMaker('blog/frontendhandler', params);
  if(result?.status === 'done'){
   setPost(result?.data);
   isSending(false)
  }else {
   history.push('/blog')
   isSending(false)
  }
 }




 return ( 
  <FrontendContext.Provider value={{
   getBlog,
   blogs,
   path,
   getPost,
   getProperties,
   getProduct,
   products,
   properties,
   post
  }}>
   {props.children}
  </FrontendContext.Provider>
 );
}
 
export default FrontendContextProvider;
