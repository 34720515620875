import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Footer from "./footer";
import Nav from "./nav";

const ViewProjects = () => {
const {id} = useParams()
const history = useHistory();
useEffect(()=> {
 if(id !== 'chanzo'){
  history.push('/')
 }
}, [])

useEffect(() => {
 window.scrollTo(0, 0); // Scroll to top of the page
}, []);


 return ( 
  <>
 <div className="bg-landing b-pro">
  <div className="scarf-2" >
   <Nav active='projects'/>
   <div className="my-col-10 xs-10 xs-off-1 off-1 xs-down-15vh down-12">
    <span className="faded my-col-3 xs-12  xs-centered top-2"><i className="btn-sm-2 fas fa-angle-left faded" onClick={()=>{history.push('/projects')}}></i> All Projects</span>
    </div>
   </div> 
</div>
<div className="my-col-10 xs-10 xs-off-1 xs-down-10 off-1 down-5 my-bottom-50">
   <div><span className="px40 faded bold xs-px20">CHANZO !</span></div>
   <div className="my-mother px13 faded-3 down-2 xs-down-10">
    <span>CHANZO is the World’s First True Pan-African Experience Resort. It’s one destination that offers the 54-Countries Tour Experience. CHANZO marries architectural masterpiece with technology to create a detailed representation of all African Countries in one destination. CHANZO itself is in the structure of the African map. It’s a structure walled with doors into the countries as stationed on the map. Entering into CHANZO is an immediate entrance into an African country. Each country is represented by: </span>
    <div className="my-mother down-1 xs-down-5">
    <div className="my-mother down-1 xs-down-2 px13">1. An Exhibition Room (For the arts, clothing, artefacts, etc.)</div>
    <div className="my-mother down-1 xs-down-2 px13">2. A Virtual Realty Dorm (An all screen room offering an immersive experience into all the iconic landmarks in the country, with a tour guide leading the experience. Views and experiences are captured and filmed in a way that it’s difficult to Differentiate images from the physical location and that of the CHANZO VR Dorm. Other facilities are: </div>
    <div className="my-mother down-2 xs-down-3 px13">i. CHANZO Square (A large hall for live presentation of music, film, workshops, etc.)  </div>
    <div className="my-mother down-1 xs-down-2 px13">ii. CHANZO Den (A fully African resort hotel offering a blend of traditional and contemporary African pieces)  </div>
    <div className="my-mother down-1 xs-down-2 px13">iii. CHANZO Market (A contemporary mall, in an African design for visitors shop their favourite African pieces.)  </div>
    <div className="my-mother down-1 xs-down-2 px13">iv. CHANZO Afro-Fitness Center (A contemporary dance studio offering fitness sessions with African dance routine to African music) </div>
    <div className="my-mother down-1 xs-down-2 px13">v. CHANZO Resort Estate (An A-Class Fully functional residential estate for locals and visitors who desire to enjoy the experience longer). </div>
    </div>    
  </div>
   <div className="my-mother down-5 xs-down-10">
    <span className="px13 faded-3">
     <div><span className="px40 faded bold xs-px20">Why CHANZO ?</span></div>
    <p className="my-mother down-2 xs-down-4">“... it is generally believed that we all originated in Africa...” We are all Africans. That line above is an except from Fran Dorey's publication in the Australian Museum Online Blog. It is one of the many reports that affirms arguably that the human species originated from Africa. While this is still being argued, it is what we chose to believe, based on the arguable fact below. Second to exploring wildlife safari or kilimanjaro climbing, people visit Africa to explore the richly diverse cultural tradition and ethnic groups. On the other hand, the most visited country in the world- France, with over 80 Million annual visitors, is majorly toured for her “landmarks”. Others may say for her romance or exploration of the arts and culture in museums. When you visit France, you’re only “amused” by structures, meals and arts. When you visit Africa, it’s a journey of discovery-of self or others. In recent times, we’ve had several people traced their origins to countries in Africa. This is just the beginning.</p> 
    <p>CHANZO (meaning “source” in swahili) is an iconic representation of Africa. CHANZO seeks to create a convergence for ease of the African exploration and discovery. You don’t have to visit the countries individually to know where your soul yearns. With CHANZO, the African discovery and due representation is easier than ever before.</p>
    </span>
   </div>
   <div className="my-mother down-5 px13 faded-3">
    <div><span className="px40 faded bold xs-px20">MISSION, VISION AND GOALS OF CHANZO</span></div>
    <div className="my-mother down-2 xs-down-10">
     <div><span className="bold faded">Mission: </span></div>
     <p className="down-1 xs-down-2 my-mother">1. To offer an easier and cheaper alternative to African tourism</p>
     <p>2. To create an easier avenue to effectively present and represent the richness in the diversity of the African cultures.</p>
     <p>3. To enhance a greater level of units among Africans and African Nations.</p>
     <div><span className="bold faded">Vision: </span></div>
     <p className="down-1 xs-down-2 my-mother">To be the World’s No.1 Destination for a Complete African Exploration. </p>
     <div><span className="bold faded">Goals: </span></div>
     <p className="down-1 xs-down-2 my-mother">In 3 years, CHANZO will launch as the world’s first single destination that offers a complete African exploration, gaining 10 Million Visitors. In 5 years, CHANZO will expand to cater for 25 Million Visitors, and up to 500% growth in cultures represented. In 10 years, CHANZO will expand to cater for 50 Million Visitors, and up to 500% growth in cultures represented. In 20 years, a replica of CHANZO for Asia will be launched in Beijing and New Delhi.</p>
    </div>
   </div>
  </div>
  <Footer/>
  </>
  );
}
 
export default ViewProjects;

