import CatSkeleton from "../../components/skeleton/catSeleton";
import { BlogContext } from "../../context/blogcontext";
import Layout from "./layout";
import {useEffect, useState,  useContext} from 'react';
import Details from "./redet";
import useUtils from "../../utils/useutiles";
import useApi from "../../hooks/useApi";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const Agents = () => {
const {agents, getAgents, setAgents} = useContext(BlogContext);
const token = localStorage.getItem('fla-admin');
const history = useHistory()
const path = 'https://encephalic-checkout.000webhostapp.com/images/';
const [view, setView] = useState(null)
const [isdeleting, setIsdeleting] = useState(false);
const [Delete, setDelete] = useState(false);
const {isSending, setToast} = useUtils();
const {requestMaker} = useApi();
  useEffect(() => {
    if(token){
      getAgents(token)
    }else {
      history.push('/admnlogin')
    }
   }, [])
   const deleteHandler = async(id)=> {
    const param = {token, id, action:"delete_agents"}
    setIsdeleting(true)
    const res = await requestMaker('agents/agenthandler', param);
    if(res?.status === 'done'){
      await getAgents(token);
      setDelete(null);
    }else {
      setToast('Error Deleting agents')
      setIsdeleting(false)
      setDelete(null);
    } 
   }

   const searchHandler = async(e) => {
    if(e.target.value === ""){
      await getAgents(token);
      return;
    }
     const result = agents?.filter((i) => i.name?.slice(0, 3) === e.target.value?.slice(0, 3))
     if(result?.length > 0){
      setToast(`${result?.length} result(s) found`);
      setAgents(result)
     }else {
      setToast('No Result Found');
     }
   }

  return ( <>
   <Layout/>
   <div className="my-col-10 off-1 xs-left xs-10">
    <div className="my-col-10 off-1 down-4">
      <div className="mother down-10 xs-down-10">
        <div className="my-mother white monR bold"><span><span className="bottom-bds">All</span> Referers</span></div>
       <div className="my-mother xs-down-3"> <div className="my-col-4 down-3 xs-down-5 xs-12" ><input type="search" onChange={(e) => {searchHandler(e)}} placeholder="Search by name"  className="monR search-input" /></div></div>
        <div className="my-col-10 px12  xs-12 xs-down-10 faded-2 down-3 monR" ><span>Advice: Admin should set up a meeting with applicants to discuss terms of referals, payment condictions, etc before applicant is given the referal code to start making referals. Applicants may be deleted if they don't meet the requirements  </span></div>
        {agents === null &&  <div className="my-mother down-5 xs-down-10"><CatSkeleton slideToShow={4}/></div>}
    {agents?.map((i, index) => (
      <div className="my-col-3 xs-6 monR xs-down-10 down-2" key={index}>    
  {Delete == `${i.id}` &&   <div className="my-bg-modal">
  <div className="my-col-4 off-4 xs-10 xs-off-1 xs-down-20vh centeed down-15 bg-faded my-bottom-50">
       <div className="col-10 off-1 down-5 xs-10 xs-off-1 xs-down-10">
      <div className="bd-bottom"><span className="bold alice">Delete Agent</span></div>
      <div className="my-mother down-3 xs-down-3 faded-2 px12"><span>Are you sure you want to permanently delete {i.name} ?</span></div>
      {isdeleting === false && <div className="my-mother down-6 xs-down-10">
        <span className="pd10 bgrad alice px13 c-pointer" onClick={()=> {setDelete(null)}}>No</span>
        <span className="pd10 off-1 xs-off-1 bg-faded alice px13 c-pointer" onClick={(e)=> {deleteHandler(i.id)}} >Yes</span>
        </div>}
       {isdeleting === true && <div className="my-mother down-6 xs-down-10">
        <span className="pd10 off-1 bgrad alice px13 c-pointer">Deleting...</span>
        </div>}
        </div>
       </div>
  </div>  }
      {view == `${i.id}` && <Details i={i} setView={setView} /> }
          <div className="my-col-11 xs-container  rad-10 my-bottom-10 bg-faded">
            <div className="my-col-10 xs-10 xs-off-1 xs-down-10 color-code-1 off-1 down-10">
              <div><span><i className="fas fa-user alic"></i></span></div>
              <div className="my-mother down-2 xs-down-10"><span className="px12">{i?.name.slice(0, 10) + "..." }</span></div>
              <div><span className="px12 faded-2"> from {i.country}</span></div>
              <div className="my-mother down-4 xs-down-10">
                <span className="btn-sm bg-black c-pointe" onClick={()=> {setDelete(i.id)}} title="Delete"><i className="fas fa-trash px12"></i></span>
                <span className="btn-sm bg-black off-2  xs-off-2 c-pointer" title="See Details" onClick={()=> {setView(i.id)}}><i className="fas fa-eye px12"></i></span>
              </div>
            </div>
          </div>
        </div>
        ))}
      </div>
    </div>
   </div>
  </> );
}
 
export default Agents;