import { useEffect } from "react";
import Nav from "../components/nav";
import { useState } from "react";
import { useRef } from "react";
import useApi from "../hooks/useApi";
import useUtils from "../utils/useutiles";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const AdminLogin = () => {
 const {requestMaker} = useApi();
 const [seepassword, setSeepassword] = useState(false);
 const [params, setParams] = useState({email:"", password:""});
 const [error, setError] = useState(null);
 const [isPending, setIspending] = useState(false);
 const emailRef = useRef()
 const history = useHistory();
 const passwordRef = useRef()
 const {setToast} = useUtils();
 var x = 0;

 const seePassword =() => {
  if(x === 0){
    setSeepassword(true)
    x = 1
  }else {
    setSeepassword(0)
    x = 0
  }
 }

 const LoginHandler = async (e)=> {
  e.preventDefault()
  if(params?.email == ""){
     setToast('Please enter email');
     emailRef.current.focus();
     return
    }
    if(params?.password == ""){
      setToast('Please enter password');
      passwordRef.current.focus();
     return
  }
  setIspending(true)
  const res = await requestMaker('blog/login', params);
  if(res?.status === 'done') {
    localStorage.setItem('fla-admin', res?.data);
    history.push('/admin/dashboard')
  }else {
    setIspending(false);
  }
 }

 useEffect(() => {
  document.body.style.backgroundColor = '#000';
 })


  return ( <>
  <Nav/>
   <div className="my-mother bg-black">
    <div className="my-col-10 off-1 down-10 xs-10 xs-off-1 xs-down-10">
      <div className="my-col-4 off-4 xs-12 xs-down-15vh down-2  my-bottom-50 bg-faded rad-10" >
        <div className="my-col-10 off-1 xs-container xs-down-4 color-code-1  down-6">
         <form onSubmit={(e) => {LoginHandler(e)}} >
         <div className="mother"><span className="px20 bold color-code-1">Admin Login</span></div>
         {error && <div className="mother down-2"><span className="color-code-1 px13">{error}</span></div> }
          <div className="mother down-2 xs-down-5">
            <div><span  className="px13 "><i className="fas fa-envelope"></i> Email</span></div>
            <div className="mother top-2" > <input ref={emailRef} onChange={(e)=> {setParams(prev => ({...prev, email:e.target.value})); setError(null)}}  type="text" required placeholder="email" className="input" /> </div>
          </div>
          <div className="mother down-3 xs-down-4">
            <div className="mother" >
              <span  className="px13 "><i className="fas fa-envelope"></i> Password</span>
              <i className={seepassword?"fas fa-eye fl-right pd-5":"fas fa-eye-slash fl-right pd-5"}></i>
            </div>
            <div className="mother top-2"> <input ref={passwordRef} onChange={(e)=> {setParams(prev => ({...prev, password:e.target.value})); setError(null)}} type={seepassword?"text":"password"} required placeholder="password" className="input" /> </div>
          </div>
          <div className="mother down-3 xs-down-5">
            {isPending? <button className="input bold promptoR bgrad" disabled >Please wait.... <i className="fas fa-spinner"></i> </button> : <button onClick={(e)=> {LoginHandler(e)}} className="input bold promptoR bgrad">Login</button>}
          </div>
         </form>
        </div>
      </div>
    </div>
   </div>
  </> );
}
 
export default AdminLogin;