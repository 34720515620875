import {useState, useEffect} from 'react';
import useApi from '../../hooks/useApi';
import moment from 'moment';
import useUtils from '../../utils/useutiles';

const Details = ({i, setView}) => {
  const [clients, setClients] = useState(null);
  const {requestMaker} = useApi();
  const now = moment();
  const formattedTime = now.format('YYYY-MM-DD HH:mm:ss');
  const {formatTransactionTime, isSending} = useUtils();
  const token = localStorage.getItem('fla-admin');

  const getClients = async() => {
   const params = {ref_code:i?.ref_code, token, action:"get_agent_client"};
   const res = await requestMaker('agents/agenthandler', params);
   if(res?.status === 'done'){
    setClients(res?.data);
   }
  }

  useEffect(() => {
    getClients(token)
  }, [])

  return ( <>
   <div className="my-bg-modal" onClick={() => {setView(null)}}>
    <div className="my-col-6 off-3 rad-10 white bgrad down-3 xs-10 xs-off-1 xs-down-10 my-bottom-50" onClick={(e)=> {e.stopPropagation()}}>
      <div className="my-col-10 off-1 down-5 xs-container xs-down-10">
        <div className="my-mother bd-bottom"><span className="px13 bold">Referer's Details</span></div>
          <div className="my-mother down-2"><span className="px12 monR">Full Name: <span className='black bold px13'>{i?.name}</span> </span></div>
          <div className="my-mother down-1"><span className="px12 monR">Ref_code: <span className='black bold px13'>{i?.ref_code}</span> </span></div>
            <div className="my-mother down-1"><span className="px12 monR">Email: <span className='black bold px13'>{i?.email}</span> </span></div>
              <div className="my-mother down-1"><span className="px12 monR">Phone Number: <span className='black bold px13'>{i?.phone}</span> </span></div>
              <div className="my-mother down-1"><span className="px12 monR">Date Applied: <span className='black bold'>{formatTransactionTime(i?.trn, formattedTime)}</span> </span></div>
              <div className="my-mother down-1"><span className="px12 monR">Clients Refered: {i?.refered_by}</span></div>
              {clients?.length < 0 && <span className='px13 black my-mother down-1'>No Client yet</span> }
             {clients?.map((o) => (
              <div className="my-col-4 xs-12 px13 monR down-2 xs-down-5">
                <div className="my-col-11 xs-12">
                <div className="btn-sm bg-black">
                  <div className="px10">{o?.name}</div>
                  <div className="px9">{formatTransactionTime(o?.trn, formattedTime)}</div>
                </div>
                </div>
              </div>
             ))}
            </div>
          </div>
        </div>
  
  </> );
}
 
export default Details;