import { useHistory } from "react-router-dom";
import useUtils from "../utils/useutiles";
import { AiOutlinePhone } from "react-icons/ai";
const Footer = () => {
 const history = useHistory()
 const {openModal} = useUtils()
 return ( <>
   <div className="my-mother my-bottom-50 bg-black">
     <div className="my-col-10 off-1 down-10 xs-12 xs-down-10 centered">
      <div><span className="px40 white xs-px20 oxygenB">Follow Us On Social Media</span></div>
     <div className="my-mother centered down-3 xs-down-5 xs-down-1">
      <a href="https://t.me/+GraNL1DJIkg0ZDg0"><span title="Telegram" className="mg-5 pd10 px30 alice"><i className="fab fa-telegram"></i></span></a>
      <a href="https://twitter.com/ArbmidasToken"><span title="Twitter" className="mg-5 pd10 px30 alice"><i className="fab fa-twitter"></i></span></a>
      <a href="https://www.linkedin.com/in/grzegorz-brodowski-a03b6b275"><span  title="Linkedin" className="mg-5 pd10 px30 alice"><i className="fab fa-linkedin"></i></span></a>
      <a href="https://u.wechat.com/kAKiUm3oOaDE_Is41AVRJsE"><span title="wechat" className="mg-5 pd10 px30 alice"><i className="fab fa-wechat"></i></span></a>
    </div>

    <div className="px20 alice my-mother down-5 xs-down-10">Address</div>
    <div>head office: </div>
     </div>
   </div>
 </> );
}
 
export default Footer;