import deo from "../videos/pexels-david-mcbee-4770380-1920x1080-30fps.mp4"
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Nav from "../components/nav";
import {AiOutlineArrowDown, AiOutlineHeatMap, AiOutlineLock, AiOutlineMail, AiOutlinePhone, AiOutlineWechat} from 'react-icons/ai'
import logo from "../images/1.jpg"
import logo1 from "../images/2.jpg"
import parse from 'react-html-parser';
import logo2 from "../images/3.jpg"
import OurOffers from "../components/ouroffers";
import Layout from "../components/layout";
import Fade from  'react-reveal/Fade'
import SiteLayout from "../components/sitelayout";
import moment from "moment";
import Footer from "../components/footer";
import useUtils from "../utils/useutiles";
import { FrontendContext } from "../context/frontendcontext";
import BlogCarosel from "../components/blogcarosel";
import CatSkeleton from "../components/skeleton/catSeleton";
import NewHome from "./newhome";
import NewNav from "../components/newNav";

const AllBlogs = () => {
  const {blogs, getBlog, path} = useContext(FrontendContext) 
  const history = useHistory(0)
  const divRef = useRef(null);
  const vidRef = useRef(null);
  const {openModal} = useUtils();
  const now = moment();
  const formattedTime = now.format('YYYY-MM-DD HH:mm:ss');
  const {formatTransactionTime} = useUtils();
  const [reverse, setReverse] = useState([]);


 useEffect(() => {
   getBlog();
 }, [])

 useEffect(() => {
  const newArr = blogs?.reverse()
 }, [blogs]);


const handleClick = () => {
 divRef.current.scrollIntoView({ behavior: 'smooth' });
};

const calendry =()=> {
 window.open('https://calendly.com/adesinajohn/30min')
}

 return ( 
   <div className="my-mother bg-faed">
    <NewNav active='AllBlogs'/>
   <div className="my-bottom-50 my-mother top-">
    <div className="my-col-10 xs-container  off-1 down-1">
     <div className="my-mother my-bottom-50">
      <div className="my-mother down-2 my-bottom-50" >
      <div className="my-mother down-10 xs-down-30 xs-centered"><span className="px50 black prosperR">Articles For You</span></div>
      <div className='my-col-8 down-1 xs-down-2 xs-12 xs-centered' ><span className='new-color-code-1 xs-px13 ralewayR'>Our focus is on fostering strong, connected communities where shared 
        values, cultural diversity, and meaningful relationships thrive.</span></div> 
      <div className="my-mother top-1">
      {blogs?.map((i, index) => (
       <>
       <div className="my-mother my-bottom-50 down-15 bg-faded xs-down-20" key={index}>
    <div className="my-col-12 xs-10 down-2 xs-off-1">
    <Fade right>
          <div className="xs-img-container hidden-ls"><img src={path + i.image} alt="" /></div>
          </Fade>
      <div className="my-col-6 xs-12 hidden-xs">
      <Fade bottom>
        <div className="new-img-container down-unset"> <img src={path + i.image} alt="" /></div>
       </Fade>
        </div>
      <div className="my-col-6 off-6 xs-12 xs-down-20 xs">
        <div className="my-col-8 off-2 xs-12 down-10">
        <Fade bottom>
          <div><span className="px60 xs-px50 lin-1 prosperR">{i.title.slice(0, 20) + "..."}</span></div>
          </Fade>
          <Fade bottom>
          <div className="my-mother xs-down-15 down-3 ralewayR black xs-px13">
          {i?.sub_title}
          </div>
          </Fade>
          <div className="my-mother down-10 xs-down-15"><span className="px13 ralewayR bd-code-2 upper-case new-cta-1"  onClick={()=> {history.push(`/post/${i.id}`)}}>READ BLOG <i className="fas mg-5 fa-arrow-right"></i> </span></div>
        </div>
    <div className="my-mother top-3 xs-down-15">
    <Fade right>
    {/* <div className="img-container-float rt-0 down-5"> <img src={path + i.image} alt="" /></div> */}
    </Fade>
    </div>
      </div>
    </div>
      </div>
       </>
        ))}
      </div>
      </div>
     </div>
    </div>
   </div>
   <div className="my-mother down-10"></div>
   <Footer/>
  </div>
 );
}
 
export default AllBlogs;