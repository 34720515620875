import { useState } from 'react';
import { AiOutlineLaptop, AiOutlineMail, AiOutlinePhone, AiOutlineWhatsApp } from 'react-icons/ai';
import logo from '../images/logoT.svg'
import useUtils from '../utils/useutiles';
const OurOffers = () => {
 const {closeModal, isSending, setToast, requestMaker} = useUtils();
 const [contact, setContact] = useState('email');
 const [sent, setSent] = useState(false);
 const [details, setDetails] = useState({
  fullname:"",
  email:"",
  action:'add_new_message',
  phonenumber:"",
  message:"",
  ref_code:""
 })
 const [error, setError] = useState(null);

 const HandleSubmitMessage = async() => {
   if(details?.email == ""){
     setError('Enter valid email');
     return;
    }
    if(details?.email.indexOf("@") < 0){
     setError('Invalid email format');
     return;
   }
  if(details?.fullname === ""){
   setError('Enter full Name');
   return;
  }
  if(details?.phonenumber === ""){
   setError('Enter Phone Number');
   return;
  }
  if(details?.message === ""){
   setError('Message filled cannot be empty');
   return;
 }
 const results = await requestMaker('user/contact', details)
 if(results?.status === 'done'){
   isSending(false);
   setToast('Message Sent!. We will contact you shortly.');
   closeModal('contact-us')
  }else {
   isSending(false);
   alert(`${results?.data}`);
  } 
}

const handleKeyPressNumberOnly = (event) => {
 const keyCode = event.keyCode || event.which;
 if (keyCode < 48 || keyCode > 57) {
   event.preventDefault();
 }
};

return ( 
 <div className="my-bg-modal bg-blur my-d-none"  id='contact-us'>
  <div className='my-col-6 p-fixed xs-container bg-black xs-down-10vh off-3 bd-code-1 down-3 my-bottom-50 co'>
   <div className="bottm-bd">
    <span className='close-btn faded down-2' onClick={()=> {closeModal('contact-us'); setError(null); setContact('email')}}><i className='fas fa-times'></i></span>
   </div>

   <div className="my-col-10 xs-container  xs-down-10 off-1">
    <div className="my-col-12 down-5 xs-cend">
    <div className='centered my-col-4 off-4 down-10 p-absolute opa1'><span className='my-logo'></span></div>
     <div className="my-mother faded bold color-code-1 hidden-xs px20"> <span className='bottom-bds'>Contact</span> Us </div>
     <div className="my-mother faded bold white hidden-ls px20"><span className='bottom-bds'>Contact</span> Us </div>
     </div>
    <div className="my-mother down-5 xs-down-10">
    {error &&  <div className="fade-in c-pointer"><span className="error-msg" onClick={() => {setError(null)}}>{error}</span></div>}
     {contact == 'email' && <div className='my-col-12 fade-in xs-12 monR white'>
     {/* <div className='px13 my-mother faded-3'> <span className='faded bold'>  <i className='fas fa-angle-left pd-30 c-pointer' onClick={()=> {setContact(false)}}></i> Send Us a Mail</span> <br /> <span className='px10 pd-30 faded'>We give swift responce...</span> </div> */}
     <div className="my-mother"><input value={details?.fullname} onChange={(e)=> (setDetails(prev => ({...prev, fullname:e.target.value})))}  type="text" className="monR input"  placeholder="FUll NAME (Please Add Title If Any e.g Mrs, Mr, Dr, etc.)"/></div>
     <div className="my-mother"><input value={details?.email} onChange={(e)=> (setDetails(prev => ({...prev, email:e.target.value})))}  type="email" className="monR input"  placeholder="EMAIL ADDRESS"/></div>
     <div className="my-mother"><input onKeyPress={handleKeyPressNumberOnly}  value={details?.phonenumber} onChange={(e)=> (setDetails(prev => ({...prev, phonenumber:e.target.value})))}  type="text" className="monR input"  placeholder="PHONE NUMBER"/></div>
     <div className="my-mother"><input  value={details?.ref_code} onChange={(e)=> (setDetails(prev => ({...prev, ref_code:e.target.value})))}  type="text" className="monR input"  placeholder="REFERER'S CODE (OPTIONAL) "/></div>
     <div className="my-mother"><textarea value={details?.message} onChange={(e)=> (setDetails(prev => ({...prev, message:e.target.value})))} className="textarea monR" placeholder="HOW MAY WE HELP YOU"></textarea></div>
     <div className='my-col-12 down-2 xs-down-5 xs-12 px13'><button className='cta-1 bg-blue unset-bd' onClick={HandleSubmitMessage}>Send <i className='fas fa-paper-plane'></i></button></div>
     {sent &&  <div><span className='faded px13'>Message sent succesfully. We will respond ASAP. Thanks ✨.</span></div>}
    </div>
    }
    </div>
   <div className="my-mother down-5 xs-centere xs-down-10">
    <a href="https://facebook.com/"><span className="pd-10 txtgrad1"><i className="fab fa-facebook"></i></span></a>
    <a href="https://twitter.com/"><span className="pd-10 txtgrad1"><i className="fab fa-twitter"></i></span></a>
    <a href="https://instagram.com/"><span className="pd-10 txtgrad1"><i className="fab fa-instagram"></i></span></a>
   </div>
   </div>
    </div>
  </div>
  );
}
 
export default OurOffers;