import { useState } from "react";
import useUtils from "../../utils/useutiles";
import useApi from "../../hooks/useApi";
import { useContext } from "react";
import { BlogContext } from "../../context/blogcontext";

const DeletePost = ({i, setDelete}) => {
  const [isdeleting, setIsdeleting] = useState(false);
  const {setToast} = useUtils();
  const {requestMaker} = useApi();
  const {token, getBlog} = useContext(BlogContext)
   const deleteHandler = async()=> {
    const param = {token, id:i?.id, action:"delete_post"}
    setIsdeleting(true)
    const res = await requestMaker('/blog/bloghandler', param);
    if(res?.status === 'done'){
      await getBlog(token);
      setToast('Post Deleted !')
      setDelete(null);
    }else {
      setToast('Error Deleting Post')
      setIsdeleting(false)
      setDelete(null);
    } 
   }

  return ( <>
   <div className="my-col-4 off-2 xs-10  xs-down-20vh centeed down-20 bg-faded delete-modal">
    <div className="col-10 off-1 down-5 xs-10 xs-off-1 xs-down-10">
      <div><span className="bold px20 alice">Delete Post</span></div>
      <div className="my-mother down-3 xs-down-3 alice px12"><span>Are you sure you want to Permanently delete this Post ?</span></div>
      {isdeleting === false && <div className="my-mother down-6 xs-down-5">
        <span className="pd10 bgrad alice px13 c-pointer" onClick={()=> {setDelete(null)}}>No</span>
        <span className="pd10 off-1 xs-off-1 bg-faded alice px13 c-pointer" onClick={deleteHandler}>Yes</span>
      </div>}
      {isdeleting === true && <div className="my-mother down-6">
        <span className="pd10 off-1 bg-faded alice px13 c-pointer">Deleting...</span>
      </div>}
    </div>
  </div>
  </> );
}
 
export default DeletePost;