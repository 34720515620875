import Layout from "./layout";
import {Editor} from '@tinymce/tinymce-react';
import { useContext, useRef } from "react";
import {useState} from 'react';
import { BlogContext } from "../../context/blogcontext";
import useUtils from "../../utils/useutiles";
import useApi from "../../hooks/useApi";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const NewProperties = () => {
  const {setToast} = useUtils();
  const {requestMaker} = useApi();
 const editorRef = useRef(); 
 const token = localStorage.getItem('fla-admin');
 const [preview, setPreview] = useState();
 const [isLiving, setIsLiving] = useState(false);
 const history = useHistory();
 const [filetype, setFileType] = useState('Image');
 const [newpost, setNewPost] = useState({
  token:token,
  title:"",
  sub_title:"",
  media:"",
  body:"",
  media_type:"",
 })

 
 const clickHandler =(id)=> {
  if(id){
    document.getElementById(id).click();
  }
 }

 const handleImageChange = async (event) => {
  const file = event.target.files[0];
  let url;

  if (file) {
    if (
      (file.type === 'image/jpeg' ||
        file.type === 'image/jpg' ||
        file.type === 'image/png') &&
       file.size < 4 * 1024 * 1024
    ) {
      setFileType('Image')
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;

        img.onload = () => {
          const canvas = document.createElement('canvas');
          const MAX_WIDTH = 400;
          const MAX_HEIGHT = 400;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
          const dataURL = canvas.toDataURL(file.type);
          setPreview(dataURL);
          url = dataURL;
        };
      };
      setNewPost((prev) => ({ ...prev, media: file, media_type:'Image'}));
    } else if (
      (file.type === 'video/mp4' ||
        file.type === 'video/mpeg' ||
        file.type === 'video/quicktime') &&
        file.size < 4 * 1024 * 1024
    ) {
      setFileType('Video')
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        url = reader.result;
        setPreview(url);
      };
      setNewPost((prev) => ({...prev, media:file, media_type:'Video' }));
    } else {
      setToast(
        'Please select an image (JPG, JPEG, PNG) less than 4MB or a video (MP4, MPEG, QuickTime) less than 4MB in size'
      );
    }
  } else {
    setToast('Please select a valid file.');
  }
};


  const submitPost = async () => {
    const body = editorRef.current.getContent();
    if(newpost?.title === ""){
      setToast("Please enter a title for your Product");
      return
    }
    if(newpost?.image === ""){
      setToast("Please enter an image for your Product");
      return
    }
    if(body === ""){
      setToast("you have'nt typed anything");
      return
    }
    newpost['body'] = body;
    setIsLiving(true);
    const res = await requestMaker('/product/newproduct', newpost)
    if(res?.status  === 'done'){
     setToast("Your Product is live");
     setIsLiving(false)
     history.push('/admin/dashboard');
    }else {
      setIsLiving(false)
      setToast('There was an error publishing this product')
    }

  }



  return ( <>
   <Layout/>
   <div className="my-col-10 off-1 xs-10 xs-left  monR">
    <div className="my-col-10 off-1 down-10 xs-centred xs-container xs-down-12">
     <div className="my-mother"> <span className="alice bold"> <span className="bottom-bds">New</span> Product</span></div>
     <div className="my-mother down-1 xs-down-3">
      <div className="my-col-4 xs-12"><input type="text" value={newpost?.title} onChange={(e)=> {setNewPost(prev => ({...prev, title:e.target.value}))}} placeholder="Product Title" className="search-input bg-faded" /></div>
       <div className="my-col-8 right hidden-xs xs-12">
        {isLiving? <span className="btn-sm-2 px10 monR bold alice bgrad">Publishing...</span>:
        <span className="btn-sm-2 px10 monR bold alice bgrad" onClick={submitPost}>Publish to Live</span>}
        {/* <span className="btn-sm-2 px10 monR bold alice bgrad off-1">Save to Draft</span> */}
       </div>
     </div>
      <div className="my-mother down-2">
      <div className="my-col-4"><input type="text" value={newpost?.sub_title} onChange={(e)=> {setNewPost(prev => ({...prev, sub_title:e.target.value}))}} placeholder="Product sub title (optional)" className="search-input bg-faded" /></div>
      </div>
      <div className="my-col-4 xs-12 xs-down-3 hidden-ls">
        <div className="my-col-10 off-1">
       <div className="my-mother xs-down-5">
        <div className="mother my-bottom-10"><span className="px13 faded-2">Product Video or Image</span></div>
        <input type="file" accept=".jpg,.jpeg,.png,.mp4,.mpeg,.mp3.quicktime" id="upload-pp_3" hidden onChange={(e) => {handleImageChange(e)}} />
        <div className="my-col-12">
        {filetype === 'Image' ?  <div className="blog-img-container rad-10" onClick={()=> {clickHandler('upload-pp_3')}} ><img src={preview} alt="" /></div>:<div  className="blog-img-container rad-10" onClick={()=> {clickHandler('upload-pp_3')}} ><video  onClick={()=> {clickHandler('upload-pp_3')}} src={preview} alt="" /></div>}
          </div></div>
        </div>
       </div>
     <div className="my-mother down-2 xs-down-5">
      <div className="mother my-bottom-10"><span className="faded-2 px13">Detailed Product description</span></div> 
       <div className="my-col-8">
       <Editor
        onInit={(evt, editor) => editorRef.current = editor }
        init={
          {
            height:250,
            color_default_background:'black'
          }
        }
       />
       </div>
       <div className="my-col-4 xs-6 xs-down-5 hidden-xs">
        <div className="my-col-10 off-1 top-10">
       <div className="my-mother">
        <div className="mother my-bottom-10"><span className="px13 alice">Product Video or Image</span></div>
        <input type="file" accept=".jpg,.jpeg,.png,.mp4,.mpeg,.mp3.quicktime" id="upload-pp_3" hidden onChange={(e) => {handleImageChange(e)}} />
        <div className="my-col-12">
          {filetype === 'Image' ?  <div className="blog-img-container rad-10" onClick={()=> {clickHandler('upload-pp_3')}} ><img src={preview} alt="" /></div>:<div className="blog-img-container rad-10" onClick={()=> {clickHandler('upload-pp_3')}} ><video  onClick={()=> {clickHandler('upload-pp_3')}} src={preview} alt="" /></div>}
         </div>
        </div>
        </div>
       </div>
       <div className="my-col-8 xs-12 xs-down-10 hidden-ls">
        {isLiving? <span className="btn-sm-2 px10 monR bold alice bgrad">Publishing...</span>:
        <span className="btn-sm-2 px10 monR bold alice bgrad" onClick={submitPost}>Publish to Live</span>}
        {/* <span className="btn-sm-2 px10 monR bold alice bgrad off-1 xs-off-1">Save to Draft</span> */}
       </div>
      
       <div className="my-bottom-50 xs-12 xs-down-1"></div>
       <div className="my-bottom-50 xs-12 xs-down-1"></div>
       <div className="my-bottom-50 xs-12 xs-down-1"></div>
     </div>
    </div>
   </div>
  </> );
}
 
export default NewProperties;