import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Nav from "../components/nav";
import parse from 'react-html-parser';
import Layout from "../components/layout";
import moment from "moment";
import Footer from "../components/footer";
import useUtils from "../utils/useutiles";
import { FrontendContext } from "../context/frontendcontext";
import { useParams } from "react-router-dom";
import NewNav from "../components/newNav";

const ViewPost = () => {
  const {post, getPost, path} = useContext(FrontendContext) 
  const history = useHistory(0)
  const divRef = useRef(null);
  const vidRef = useRef(null);
   const now = moment();
  const formattedTime = now.format('YYYY-MM-DD HH:mm:ss');
  const {formatTransactionTime} = useUtils();
  const {id} = useParams();
  
  // alert(id)

 useEffect(() => {
  if(id) {
    getPost(id);
  }else {
    history.push('/blog')
  }
 }, []);


const handleClick = () => {
 divRef.current.scrollIntoView({ behavior: 'smooth' });
};

const calendry =()=> {
 window.open('https://calendly.com/adesinajohn/30min')
}

 return ( 
   <div className="my-mother">
    <NewNav active='ViewPost'/>
   <div className="my-bottom-50 my-mother down-5 xs-down-7vh">
    <div className="my-col-10 xs-container off-1 down-3">
      <div className="my-mother xs-down-4">
       <div className="my-col-12 xs-down-2">
       <div className="px30  my-mother xs-down-5"><span className="fas fa-arrow-left" onClick={()=> {history.push('/blog')}}></span></div>
        <div className="my-col-11"> 
        <div className="my-mother xs-centeed xs-down-1"><span className="px40 bold prosperR">{post?.title}<span className="hidden-ls"></span></span></div>
        <div className="my-mother monR xs-down-3 xs-cetered">
         <div className="my-mother down-1 bd-bottom"><span className="ralewayR">{post?.sub_title}</span></div>
        <div className="my-mother down-3 xs-down-5 justify"><span className=" px13 ralewayR">{parse(`${post?.body}`)}</span></div>
        </div>
       </div>
      </div>
      <div className="my-col-4 xs-12"> 
      <div className="view-img-container down-2"><img src={path + post?.image} alt="" /></div>
      </div>
    </div>
    </div>
   </div>
   {/* <div className="my-mother bg-black centered my-bottom-50"  ref={divRef}>
    <div className="my-mother xs-down-5 hidden-ls px30"><span>✨</span></div>
    <div className="my-col-10 off-1 down-5 xs-10 xs-off-1 bg-black">
      <div className="my-mother"><span className="px30 bold alice"><span className="bottom-bds">Events</span> For You <span className="hidden-xs">✨</span></span></div>
     <div className="my-mother xs-down-3">
      <div className="xs-12 xs-down-10 hid bold px30 faded-2 my-col-12 down-3"><Layout/></div>
     </div>
    </div>
   </div>  */}
   <Footer/>
  </div>
 );
}
 
export default ViewPost;