import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Footer from "../components/footer";
import Nav from "../components/nav";
import OurOffers from "../components/ouroffers";

const Projects = () => {
 const history = useHistory(0)
 useEffect(() => {
  window.scrollTo(0, 0); // Scroll to top of the page
}, []);

const calendry =()=> {
 window.open('https://calendly.com/adesinajohn/30min')
}
 
 return ( 
<>
  <div className="">
   <div className="bg-landing">
    <div className="dark-scarf" >
    {/* <span className="square-deco"></span>
     <span className="square-deco1"></span>
     <span className="square-deco2"></span>
     <span className="square-deco4 hidden-xs"></span>
     <span className="square-deco3"></span> */}
    <Nav active='projects'/>
     <div className="my-col-10 xs-10 xs-off-1 off-1 xs-down-15vh down-12">
      <div className="my-mother down-3 xs-down-10"><span className="faded px60 xs-px40 bold">EXPLORE <br /> OUR <span className="txtgrad1">Properties</span></span></div>
     </div>
     </div>
  </div>
 <div className="my-mother my-bottom-50 bg-black">
    <div className="my-col-10 xs-10 xs-off-1 xs-down-10 off-1 down-5">
     <div className="my-col-4 xs-12 xs-down-3">
      <div className="my-card my-bottom-50">
        <div className="my-col-10 off-1 xs-10 xs-down-10 xs-off-1 down-10">
         <div className="my-mother down-10"><span className="faded bold px30">CHANZO</span></div>
         <div className="my-mother down-5 xs-down-5">
          <span className="px13 faded-3"> CHANZO is the World’s First True Pan-African Experience Resort. It’s one destination that offers the 54-Countries Tour Experience...  </span>
        </div>
        <div className="my-mother xs-down-10 down-8"><span className="btn-explore  white px13" onClick={()=>{history.push('/projects/chanzo')}}>Explore Chanzo</span></div>
        </div>
      </div>
     </div>

     <div className="my-col-4 xs-12 xs-down-3">
      <div className="my-card my-bottom-50">
        <div className="my-col-10 off-1 xs-10 xs-down-10 xs-off-1 down-10">
         <div className="my-mother down-10"><span className="faded bold px30">CHANZO V2.0</span></div>
         <div className="my-mother down-5 xs-down-5">
          <span className="px13 faded-3"> CHANZO is the World’s First True Pan-African Experience Resort. It’s one destination that offers the 54-Countries Tour Experience...  </span>
        </div>
        <div className="my-mother xs-down-10 down-8"><span className="btn-explore  white px13">Coming Soon</span></div>
        </div>
      </div>
     </div>


     <div className="my-col-4 xs-12 xs-down-3">
      <div className="my-card my-bottom-50">
        <div className="my-col-10 off-1 xs-10 xs-down-10 xs-off-1 down-10">
         <div className="my-mother down-10"><span className="faded bold px30">CHANZO V2.1</span></div>
         <div className="my-mother down-5 xs-down-5">
          <span className="px13 faded-3"> CHANZO is the World’s First True Pan-African Experience Resort. It’s one destination that offers the 54-Countries Tour Experience...  </span>
        </div>
        <div className="my-mother xs-down-10 down-8"><span className="btn-explore  white px13">Coming Soon</span></div>
        </div>
      </div>
     </div>


    </div>
 </div>
 <div className="bg-landing-2 my-mother xs-down-10 bg-color-code-3 down-4">
      <div className="scarf-2 ">
      <div className="my-col-10 xs-10 xs-off-1 off-1 down-6 xs-down-10vh">
       <div className="my-col-5 down-4"> <div><span className="px50 xs-px30 bold faded">SPEAK WITH A LEAD INVESTOR.</span></div></div>
       <div className="my-col-4 off-2 xs-12 xs-down-5">
         <div><span className="px30 faded xs-px20">Schedule an online session today</span></div>
        <div> <div className="btn-explore down-10 xs-down-10 faded xs-px10" onClick={calendry}>CLICK TO MEET</div></div>
       </div>
      </div>
      </div> 
     </div>

<Footer/>



  </div>
  </>
 );
}
 
export default Projects;