import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
const NewNav = () => {
  const history = useHistory();
  const [openNav, setOpenNav] = useState(false);
  const navHandler = () => {
   openNav ? setOpenNav(false) : setOpenNav(true)  
  }

   
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const scrollThreshold = 100; // Adjust this value based on when you want the color change to happen

      // Check if the user has scrolled beyond the threshold
      setIsScrolled(scrollTop > scrollThreshold);
    };

    // Attach the scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array ensures the effect runs only once on mount



  return ( <>
   <nav className={`hidden-xs ${isScrolled? 'new-nav-2' : "new-nav"}`}>
    <div className="my-col-10 off-1">
      <div className="my-col-3">
        <span className="new-logo-layout" onClick={()=> {history.push('/')}}></span>
      </div>
      <div className="my-col-9 centered off-2">
      {/* <span className={isScrolled? 'new-nav-links-2': 'new-nav-links'}onClick={()=> {history.push('/')}}>HOME</span> */}
      <span className={isScrolled? 'new-nav-links-2': 'new-nav-links'} onClick={()=> {history.push('/about')}}>ABOUT</span>
      <span className={isScrolled? 'new-nav-links-2': 'new-nav-links'} onClick={()=> {history.push('/contact')}}>CONTACT</span>
      <span className={isScrolled? 'new-nav-links-2': 'new-nav-links'} onClick={()=> {window.open('https://arbmidas.com/')}}>TOKEN</span>
      <span className={`last-child ralewayR px15 ${isScrolled? 'white': 'alice'}`}>Info@arbmidasisl.com</span>
      <span className="last-child rad-10 off-2 oxygenB n-bg-color-code-1 white c-pointer upper-case" onClick={()=> {window.open('https://arbmidasnft.com/')}}> Arbmidas Nfts</span>
      </div>
    </div>
   </nav>
   <div className={`new-mobile-nav hidden-ls ${openNav && 'new-mobile-nav-open bg-black'}`}>
    <div className="xs-container xs-down-4">
      <div className="xs-6"><i className={`pd10 xs-px20 fas fa-bars ${openNav ? 'white' : 'white'}`} onClick={navHandler}></i></div>
      <div className="xs-5 xs-off-1 "><span className="xs-my-logo" onClick={()=> {history.push('/')}}></span> <span className="xs-off-3 xs-12 xs-down-3 upper-case white pd10">ArbmidasISL</span></div>
      <div className="xs-12 xs-down-5">
       <span className="xs-nav-link" onClick={()=> {history.push('/')}}> Home</span>
       <span className="xs-nav-link" onClick={()=> {history.push('/about')}}> About </span>
       <span className="xs-nav-link" onClick={()=> {window.open('https://arbmidas.com/')}}> Token </span>
       <span className="xs-nav-link" onClick={()=> {history.push('/contact')}}> Contact </span>
       <span className="xs-nav-link centered n-bg-color-code-1 xs-down-5 white bold" onClick={()=> {window.open('https://arbmidasnft.com/')}}>Arbmidas Nfts </span>
      </div>
    </div>
   </div>             
   <div className="whatsaap">We are here fo you</div>
  </> );
}
 
export default NewNav;