import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Footer from "../components/footer";
import Nav from "../components/nav";
import OurOffers from "../components/ouroffers";
import useUtils from "../utils/useutiles";

const Prices = () => {
 const {openModal} = useUtils();
 const history = useHistory(0)
//  useEffect(() => {
//   window.scrollTo(0, 0); // Scroll to top of the page
// }, []);

const calendry =()=> {
 window.open('https://calendly.com/adesinajohn/30min')
}

 
 return ( 
  <>
   <OurOffers/>
    <Nav active='offers'/>
  <div className="">
  <div className="bg-landing h-300">
   <div className="dark-scarf h-300">
   {/* <span className="square-deco"></span>
     <span className="square-deco1"></span>
     <span className="square-deco2"></span>
     <span className="square-deco4 hidden-xs"></span>
     <span className="square-deco3"></span> */}
     <div className="my-col-12 xs-10 xs-off-1 xs-centered xs-down-9vh down-9">
      <div className="my-col-10 off-1 xs-12 xs-down-3">
       <div className="my-mother top-1 px60 xs-px40 white bold xs-down-2"> <span className="txtgrad1">Explore</span>  <span>Our Properties</span></div>
        <div className="my-mother xs-down-3"><span className="px20 faded-2 xs-px13">where sincererity meets serenity...</span></div>
        {/* <div className="my-mother down-5 xs-down-10"><span className="cta-1">Get Started <i className="fas fa-arrow-down"></i></span></div> */}
     </div>
     </div>
   </div>
    </div>
   <div className="my-mother my-bottom-50 bg-black">
    <div className="my-col-10 off-1 xs-container xs-down-10">
     <div className="my-mother">
       {/* <div className="my-mother"><span className="px30 faded-3 bold">Adeshina <br /> AdedeJi <br /> John</span></div> */}
       {/* <div className="my-col-10 off-1 down-5 bg-white xs-10 xs-off-1 hidden-xs">
        <div className="my-mother pd-30 black">
         <div className="my-container xs-8 xs-off-2 xs-px10 bold centered xs-left">
           <div><span>50% LIMITED PRE-LAUNCH OFFER (RESIDENTIAL)</span></div>
           <div><span>All Fees included. No extra cost</span></div>
         </div>
        </div>
       </div> */}
        <div className="my-col-12 down-5 my-bottom-50 centred">
         <div className="centered"><span className="faded upper-case px20 bold"></span>
         {/* <div className="hidden-ls my-mother xs-down-5 px13 faded"><span>50% LIMITED PRE-LAUNCH OFFER (RESIDENTIAL).All Fees included. No extra cost </span></div> */}
         </div>
         <div className="my-mother down-5 xs-down-10">
         <div className="my-col-4 xs-12 xs-down-3">
         <div className="my-card">
          <div className="my-col-10 off-1 xs-10 xs-down-10 xs-off-1 down-5">
          <div className="my-mother down-10"><span className="faded bold px20">450 Square Meter</span></div>
          <div className="my-mother down-5 xs-down-5">
           <div className="xs-12 xs-down-2"><span className="faded"><b>Actual Price</b>: ₦4,500,000</span></div>
           <div className="my-mother down-5 xs-down-8 faded"><span className="bold"></span></div>
           <span className="faded-2 px13">Afro-Live centre, Festival Arena, Dedicated Garden, Afro-fitnes Hub, Afro-Pieces Mart, Eco Friendly Amenities and more...</span>
           <div className="my-mother down-5 xs-down-8"><span className="faded"><b>Initial deposite</b>: ₦1,500,000</span></div>
           <div className="my-mother"><span className="px10 faded"><span className="faded"> 3-6 Months Payment Plan. No Extra Cost</span></span></div>
          </div>
         <div className="my-mother xs-down-8 down-8"><span className="cta-1 bg-blue white px13" onClick={()=> {openModal('contact-us')}}>Reach Us</span></div>
        </div>
        </div>
       </div>
       <div className="my-col-4 xs-12 xs-down-3">
         <div className="my-card">
          <div className="my-col-10 off-1 xs-10 xs-down-10 xs-off-1 down-5">
          <div className="my-mother down-10"><span className="faded bold px20">1,000 Square Meter</span></div>
          <div className="my-mother down-5 xs-down-5">
           <div className="xs-12 xs-down-2"><span className="faded"><b>Actual Price</b>: ₦10,000,000</span></div>
           <div className="my-mother down-5 xs-down-8 faded"><span className="bold"></span></div>
           <span className="faded-2 px13">Afro-Live centre, Festival Arena, Dedicated Garden, Afro-fitnes Hub, Afro-Pieces Mart, Eco Friendly Amenities and more...</span>
           <div className="my-mother down-5 xs-down-8"><span className="faded"><b>Initial deposite</b>: ₦3,000,000</span></div>
           <div className="my-mother"><span className="px10 faded"><span className="faded"> 3-6 Months Payment Plan. No Extra Cost</span></span></div>
          </div>
         <div className="my-mother xs-down-8 down-8"><span className="cta-1 bg-blue white px13" onClick={()=> {openModal('contact-us')}}>Reach Us</span></div>
        </div>
        </div>
       </div>
       <div className="my-col-4 xs-12 xs-down-3">
         <div className="my-card">
          <div className="my-col-10 off-1 xs-10 xs-down-10 xs-off-1 down-5">
          <div className="my-mother down-10"><span className="faded bold px20">2 Bedrooms + visitor's Room</span></div>
          <div className="my-mother down-5 xs-down-5">
           <div className="xs-12 xs-down-2"><span className="faded"><b>Actual Price</b>: ₦35,000,000</span></div>
           <div className="my-mother down-5 xs-down-8 faded"><span className="bold"></span></div>
           <span className="faded-2 px13">24/7 Power, Pre-installed Sola System, Centralised Sewage System, Excellent Drainage, Centralized Water Supply, Excellent Road Network, 24/7 security and more...</span>
           <div className="my-mother down-5 xs-down-8"><span className="faded"><b>Initial deposite</b>: ₦5,000,000</span></div>
           <div className="my-mother"><span className="px10 faded"><span className="faded"> 3-6 Months Payment Plan. No Extra Cost</span></span></div>
          </div>
         <div className="my-mother xs-down-8 down-8"><span className="cta-1 bg-blue white px13" onClick={()=> {openModal('contact-us')}}>Reach Us</span></div>
        </div>
        </div>
       </div>
       <div className="my-col-4 xs-12 xs-down-3 down-3">
         <div className="my-card">
          <div className="my-col-10 off-1 xs-10 xs-down-10 xs-off-1 down-5">
          <div className="my-mother down-10"><span className="faded bold px20">3 Bedrooms + visitor's Room</span></div>
          <div className="my-mother down-5 xs-down-5">
           <div className="xs-12 xs-down-2"><span className="faded"><b>Actual Price</b>: ₦40,000,000</span></div>
           <div className="my-mother down-5 xs-down-8 faded"><span className="bold"></span></div>
           <span className="faded-2 px13">24/7 Power, Pre-installed Sola System, Centralised Sewage System, Excellent Drainage, Centralized Water Supply, Excellent Road Network, 24/7 security and more...</span>
           <div className="my-mother down-5 xs-down-8"><span className="faded"><b>Initial deposite</b>: ₦5,000,000</span></div>
           <div className="my-mother"><span className="px10 faded"><span className="faded"> 3-6 Months Payment Plan. No Extra Cost</span></span></div>
          </div>
         <div className="my-mother xs-down-8 down-8"><span className="cta-1 bg-blue white px13" onClick={()=> {openModal('contact-us')}}>Reach Us</span></div>
        </div>
        </div>
       </div>




    
         </div>
        </div>


{/* 
         <div className="my-mother bd-code-1 my-bottom-10">
          <div className="my-col-2 xs-3 down-1 bold xs-left centered pd-30">
           <span className="bd-sied-1"></span>
            <span className="px20 xs-px9 faded"># 1.25M</span>
            <div className="my-mother faded down-1 px13 xs-px9">150SQM</div>
          </div>
          <div className="my-col-2 xs-2 down-1 bold xs-left centered pd-30 ">
          <span className="bd-sied-1"></span>
            <span className="px20 xs-px9 faded">#2.25M</span>
            <div className="my-mother faded down-2 px13 xs-px9">300SQM</div>
          </div>
          <div className="my-col-3 xs-3 down-1 bold xs-left centered pd-30 ">
            <span className="bd-sied-1"></span>
           <div className="my-mother down-4"> <span className="px13 xs-px9 faded">PRE-LAUNCH PRICE</span></div>
          </div>
          <div className="my-col-2 xs-2  down-1 bold xs-left centered pd-30 ">
          <span className="bd-sied-1"></span>
            <span className="px20 xs-px9 faded">#4.25M</span>
            <div className="my-mother faded down-2 px13 xs-px9">600SQM</div>
          </div>
          <div className="my-col-2 xs-2  down-1 bold right xs-left centered pd-30 ">
            <span className="px20 xs-px9 faded">#6.9M</span>
            <div className="my-mother faded down-2 px13 xs-px9">1000SQM</div>
          </div>
         </div> */}
         {/* <div className="my-col-12 xs-12 bd-code-1 my-bottom-10">
          <div className="my-col-2 xs-3 down-1 bold xs-left centered pd-30">
           <span className="bd-sied-1"></span>
            <span className="px20 xs-px9 faded"><s>#2.5M</s></span>
            <div className="my-mother faded down-1 px13 xs-px9">150SQM</div>
          </div>
          <div className="my-col-2 xs-2 down-1 bold xs-left centered pd-30 ">
          <span className="bd-sied-1"></span>
            <span className="px20 xs-px9 faded"><s>#4.5M</s></span>
            <div className="my-mother faded down-2 px13 xs-px9">300SQM</div>
          </div>
          <div className="my-col-3 xs-3 down-1 bold xs-left centered pd-30 ">
            <span className="bd-sied-1"></span>
           <div className="my-mother down-4"> <span className="px13 xs-px9 faded">ACTUAL PRICE</span></div>
          </div>
          <div className="my-col-2 xs-2 down-1 bold xs-left centered pd-30 ">
          <span className="bd-sied-1"></span>
            <span className="px20 xs-px9 faded"><s>#8.5M</s></span>
            <div className="my-mother faded down-2 px13 xs-px9">600SQM</div>
          </div>
          <div className="my-col-2 xs-2 down-1 bold right xs-left centered pd-30 ">
            <span className="px20 xs-px9 faded"><s>#13.8</s></span>
            <div className="my-mother faded down-2 px13 xs-px9">1000SQM</div>
          </div>
         </div> */}
         {/* <div className="my-mother xs-down-10 xs-px13 centered down-3"><span className="bold faded"> 3-6 Months Payment Plan. No Extra Cost</span></div> */}
     </div>
    </div>
    {/* <div className="bg-landing-2 my-mother xs-down-10 bg-color-code-3 down-4">
      <div className="scarf-2 ">
      <div className="my-col-10 xs-10 xs-off-1 off-1 down-6 xs-down-10vh">
       <div className="my-col-5 down-4"> <div><span className="px50 xs-px30 bold faded">SPEAK WITH A LEAD INVESTOR.</span></div></div>
       <div className="my-col-4 off-2 xs-12 xs-down-5">
         <div><span className="px30 faded xs-px20">Schedule an online session today</span></div>
        <div> <div className="cta-1 bg-bluedown-10 xs-down-10 faded xs-px10" onClick={calendry}>CLICK TO MEET</div></div>
       </div>
      </div>
      </div>
     </div> */}
   </div>

<Footer/>



  </div>
  </>
 );
}
 
export default Prices;