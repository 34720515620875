import { useState, useEffect } from "react";
import useApi from "../hooks/useApi";
import Nav from "../components/nav";

const FormData = () => {
  const [data, setData] = useState(null);
  const {requestMaker} = useApi();


  const getData = async () => {
   const param = {action: "getdata"};
   const res = await requestMaker('user/aaj', param);
   if(res?.status === 'done') {
    setData(res?.data);
   }
  }

 useEffect(() => {
  getData();
 }, [])

 const deleteData = async (id) => {
  const param = {action: "delete", id};
  const res = await requestMaker('user/aaj', param);
  if(res?.status === 'done') {
   alert('Data deleted');
  }
 }


  return ( <>
  <Nav/>
     <div className="my-col-10 off-1 down-11 alice px13 centered">
      <div className="my-mother bd-bottom bold">
      <div className="my-col-1">
        <div className="my-container">Id</div>
      </div>
      <div className="my-col-3">
        <div className="my-container">Full Name</div>
      </div>
      <div className="my-col-2">
        <div className="my-container">Country</div>
      </div>
      <div className="my-col-3">
        <div className="my-container">Email</div>
      </div>
      <div className="my-col-3">
        <div className="my-container">Phone Number</div>
      </div>
      </div>
      {data?.map((i, index) => ( 
      <div className="my-mother down-1 bd-bottom">
      <div className="my-col-1">
        <div className="my-container">{index + 1}</div>
      </div>
      <div className="my-col-3">
        <div className="my-container">{i.name}</div>
      </div>
      <div className="my-col-2">
        <div className="my-container">{i.country}</div>
      </div>
      <div className="my-col-3">
        <div className="my-container">{i.email}</div>
      </div>
      <div className="my-col-3">
        <div className="my-container">{i.phone}</div>
      </div>
      </div>
      ))}

     </div>
  </> );
}
 
export default FormData;