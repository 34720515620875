import {createContext, useEffect, useState} from 'react';
import useApi from '../hooks/useApi';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


export const BlogContext = createContext();

const BlogContextProvider = (props) => {
 const token = localStorage.getItem('fla-admin');
 const path = 'https://encephalic-checkout.000webhostapp.com/images/'
 const {requestMaker} = useApi();
 const [blogs, setBlogs] = useState(null)
 const [message, setMessages] = useState(null);
 const [newMesaage, setNewMessage] = useState(null);
 const [agents, setAgents] = useState(null);
 const history = useHistory();
 const [Products, setProducts] = useState(null);
 
 const logoutOut = () => {
  localStorage.removeItem('fla-admin');
  setBlogs(null);
  history.push('/adminlogin')
 }

 const getBlog = async(Token) => {
  const params = {token:Token, action:'get_posts'}
  const result = await requestMaker('blog/bloghandler', params);
  if(result?.status === 'done'){
   setBlogs(result?.data);
  }
  if(result?.status === 'expired'){
    logoutOut()
  }
 }

 const getProducts = async(Token) => {
  const params = {token:Token, action:'get_posts'}
  const result = await requestMaker('product/producthandler', params);
  if(result?.status === 'done'){
    setProducts(result?.data);
  }
  if(result?.status === 'expired'){
    logoutOut()
  }
 }

 const getMessage = async(Token) => {
  const params = {token:Token, action:'get_message'}
  const result = await requestMaker('message/messagehandler', params);
  if(result?.status === 'done'){
    setMessages(result?.data);
   const newMsg =  result?.data?.filter((i) => i.replied == 0);
   setNewMessage(newMsg)
  }if(result?.status === 'expired'){
    logoutOut()
  }
 }

 const getAgents = async(Token) => {
  const params = {token:Token, action:'get_agents'}
  const result = await requestMaker('agents/agenthandler', params);
  if(result?.status === 'done'){
    setAgents(result?.data);
  }if(result?.status === 'expired'){
    logoutOut()
  }
 }

 useEffect(() => {
  if(token){
    getMessage(token)
  }
 }, [])

 return ( 
  <BlogContext.Provider value={{
   getBlog,
   blogs,
   logoutOut,
   token,
   path,
   getMessage,
   setBlogs,
   message,
   newMesaage,
   setMessages,
   setAgents,
   getAgents,
   agents,
   setProducts,
   getProducts,
   Products

  }}>
   {props.children}
  </BlogContext.Provider>
 );
}
 
export default BlogContextProvider;
