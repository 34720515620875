import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Nav from "../components/nav";
import parse from 'react-html-parser';
import moment from "moment";
import Footer from "../components/footer";
import Fade from 'react-reveal/Fade';
import useUtils from "../utils/useutiles";
import { FrontendContext } from "../context/frontendcontext";
import { useParams } from "react-router-dom";
import NewNav from "../components/newNav";

const ViewProducts = () => {
  const {products, getProduct, path} = useContext(FrontendContext) 
  const history = useHistory(0)
  const divRef = useRef(null);
  const now = moment();
  const formattedTime = now.format('YYYY-MM-DD HH:mm:ss');
  const {formatTransactionTime, openModal} = useUtils();
  const {id} = useParams();


 useEffect(() => {
  if(id) {
    getProduct(id);
  }else {
    history.push('/gallery')
  }
 }, []);


 return ( 
   <div className="my-mother">
    <NewNav active='Viewproducts'/>
    <div className="my-mother my-bottom-50  down-8 xs-down-20">
    <div className="px30  xs-10 xs-off-1 xs-down-4 hidden-ls"><span className="fas fa-angle-left" onClick={()=> {history.push('/')}}></span></div>
    <div className="my-col-12 xs-10 xs-down-10 xs-off-1">
    <Fade right>
          <div className="xs-img-container xs-12 xs-off-0 hidden-ls">{products.media_type === 'Image'?<img src={path + products.media} alt="" /> : <video  src={path + products.media} alt="" />}</div>
          </Fade>
      <div className="my-col-6 xs-12 hidden-xs">
      <Fade bottom>
        <div className="new-img-container"> {products.media_type === 'Image'?<img src={path + products.media} alt="" /> : <video  src={path + products.media} alt="" />}</div>
       </Fade>
        </div>
      <div className="my-col-6 off-6 xs-12 xs-down-20 xs-centered">
        <div className="my-col-8 off-2 xs-12 down-10">
        <Fade bottom>
          <div><span className="px60 xs-px50 lin-1 prosperR">{products.title}</span></div>
          </Fade>
          <Fade bottom>
          <div className="my-mother xs-down-15 down-3 QuestR px12 xs-px13">
          {products?.sub_title}
          </div>
          </Fade>
          <div className="my-mother down-10 xs-down-15"><span className="bd-code-2 upper-case new-cta-1 "  onClick={()=> {history.push(`/product/${products.id}`)}}>VIEW FEATURES</span></div>
        </div>
    <div className="my-mother top-8 xs-down-15">
    <Fade right>
    <div className="img-container-float rt-0 down-5"> {products.media_type === 'Image'?<img src={path + products.media} alt="" /> : <video  src={path + products.media} alt="" />}</div>
    </Fade>
    </div>
      </div>
    </div>
   </div>
   

   <div className="my-bottom-50 my-mother down-15 xs-down-7vh">
   <Fade bottom>
    <div className="my-container xs-10 xs-off-1  xs-down-20 down-1">
      <div className="my-mother xs-centeed xs-down-10 down-2"><span className="px60 xs-px30 prosperR bol">{products?.title} Features<span className="hidden-ls"></span></span></div>
      <div className="my-mother down-1 bd-bottom  xs-down-1 xs-cetered">
        {/* <span className="">{products?.sub_title}</span> */}
      </div>
      <div className="my-mother down-1 xs-down-4">
       <div className="my-mother xs-down-2 down-2 black justify"> <span className="px13 ralewayR">{parse(`${products?.body}`)}</span></div>
      </div>
    <div className="my-mother down-3 xs-down-10">
    <span className="px13 ralewayR bd-code-2 upper-case new-cta-1 c-pointer" onClick={()=>{openModal('contact-us')}}>  <i className="fas fa-envelope pd10"></i>  Contact Us</span>
  </div>
    </div>
    </Fade>
   </div>
   <Footer/>
  </div>
 );
}
 
export default ViewProducts;