import deo from "../videos/pexels-david-mcbee-4770380-1920x1080-30fps.mp4"
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Nav from "../components/nav";
import {AiOutlineArrowDown, AiOutlineHeatMap, AiOutlineLock, AiOutlineMail, AiOutlinePhone, AiOutlineWechat} from 'react-icons/ai'
import logo from "../images/1.jpg"
import logo1 from "../images/2.jpg"
import parse from 'react-html-parser';
import logo2 from "../images/3.jpg"
import OurOffers from "../components/ouroffers";
import Layout from "../components/layout";
import Fade from  'react-reveal/Fade'
import SiteLayout from "../components/sitelayout";
import moment from "moment";
import Footer from "../components/footer";
import useUtils from "../utils/useutiles";
import { FrontendContext } from "../context/frontendcontext";
import CatSkeleton from "../components/skeleton/catSeleton";

const Gallery = () => {
  const {properties, getProperties, path} = useContext(FrontendContext) 
  const history = useHistory(0)
  const divRef = useRef(null);
  const vidRef = useRef(null);
  const {openModal} = useUtils();
  const now = moment();
 const formattedTime = now.format('YYYY-MM-DD HH:mm:ss');
 const {formatTransactionTime} = useUtils()

 useEffect(() => {
  getProperties();
 }, [])

const calendry =()=> {
 window.open('https://calendly.com/adesinajohn/30min')
}

 return ( 
   <div className="my-mother bg-black">
    <Nav active='gallery'/>
    <div className="bg-landing">
      <div className="dark-scarf">
        <div className="my-col-10 off-1 xs-container xs-down-30 down-5 centered">
          <div className="px50 bold white xs-px40">Properties For You</div>
          <div className="xs-12 xs-down-2"><span className="monR alice">Where Sustainability meets serenity.</span></div>
          <div className="my-mother down-3 xs-down-10 centered"><span className="white  px30 "><i className="fas fa-bounce fa-angle-double-down"></i></span></div>
        </div>
      </div>
    </div>
   <div className="my-bottom-50 bg-black my-mother down-1">
    <div className="my-col-10 xs-container top-10  off-1 down-1 xs-top-15">
     <div className="my-mother">
      {/* <div className="alice px13 monR">Recently Posted <i className="fas fa-arrow-down"></i> </div> */}
      <div className="my-mother down-2">{properties?.length < 1 && <CatSkeleton slideToShow={4} />}</div> 
      <div className="my-mother down-2">
      {properties?.map((i, index) => (
       <>
      <div className="my-col-4 xs-12 xs-down-5" key={index}>
        <div className="my-col-11 xs-12">
          <div className="cat-img-container rad-unet xs-12">
            <div className="p-overlay">
              <div className="my-col-10 off-1 xs-10 xs-off-1 xs-down-5 down-5">
              <div className="my-mother"><span className="px20 bold white">{i.title}</span></div>
             <div className="my-mother top-1 xs-top-1"><span className="px13 alice monR">{i.sub_title}</span></div>
             <div className="my-mother down-10 xs-down-1 xs-down-8"><span className="cta-1 bold bg-color-code-2 px13 white"  onClick={()=> {history.push(`/product/${i.id}`)}}>See Details <i className="fas fa-arrow-right"></i></span></div>
              </div>
             </div>
             {i.media_type === 'Image'?<img src={path + i.media} alt="" /> : <video  src={path + i.media} alt="" />}
          </div>
        </div>
       </div>
        </>
        ))}
      </div>
     </div>
    </div>
   </div>
   <Footer/>
  </div>
 );
}
 
export default Gallery;