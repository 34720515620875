import { useContext, useEffect, useState } from "react";
import Layout from "./layout";
import { BlogContext } from "../../context/blogcontext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import parse from 'react-html-parser';
import moment from "moment";
import useUtils from "../../utils/useutiles";
import EditPost from "./aditpost";
import DeletePost from "./deletepost";
import CatSkeleton from "../../components/skeleton/catSeleton";


const AdminHome = () => {
  const {getBlog, setBlogs, blogs, path} = useContext(BlogContext);
  const token = localStorage.getItem('fla-admin');
  const history = useHistory();
  const {formatTransactionTime, setToast} = useUtils();
  const now = moment();
  const [edit, setEdit] = useState(null);
  const [deletePost, setDelete] = useState(null);
  const formattedTime = now.format('YYYY-MM-DD HH:mm:ss');

 useEffect (()=> {
  if(token){
    getBlog(token);
  }
 }, [])

 useEffect(() => {
  document.body.style.backgroundColor = '#000';
 })
   
 const searchHandler = async(e) => {
  if(e.target.value === ""){
    await getBlog(token);
    return;
  }
   const result = blogs?.filter((i) => i.title?.slice(0, 3) === e.target.value?.slice(0, 3))
   if(result?.length > 0){
    setBlogs(result)
    setToast(`${result?.length} results found !`)
   }else {
    setToast('No Result Found');
   }
 }

  return ( <>
   <Layout/>
   <div className="my-col-10 off-1 xs-left xs-10 xs-down">
    <div className="my-col-10 off-1 down-10 xs-container">
     <div className="my-mother xs-down-8"> <span className="alice bold monR"> <span className="bottom-bds">All</span> Blog Posts</span></div>
     <div className="my-mother xs-down-3"> <div className="my-col-4 down-3 xs-down-5 xs-10 hidden-ls" ><input type="search" onChange={(e) => {searchHandler(e)}} placeholder="Search by title"  className="monR search-input" /></div></div>
     <div className="my-mother down-1 xs-down-3 hidden-xs">
      <div className="my-col-3"><input type="text" placeholder="Search by title" onChange={(e) => {searchHandler(e)}} className="search-input monR bg-faded" /></div>
       <div className="my-col-1 top-3"><i className="pd10 px13 alice fas fa-search adlinks"></i></div>
       <div className="my-col-8 right xs-12 top-1">
        <span className="btn-sm-2 px13 alice bgrad" onClick={() => {history.push('/admin/newpost')}}>Add New Post</span>
       </div>
     </div>
      <div className="my-mother down-1 xs-down-10">
        {blogs === null && <CatSkeleton slideToShow={4} />}
        {blogs?.map((i, index) => (
        <>
        {edit === `edit_${i.id}` && <EditPost setEdit={setEdit} i={i} /> }
        {deletePost === `delete_${i.id}` && <DeletePost setDelete={setDelete} i={i} /> }
          <div className="my-col-4 xs-12 xs-down-5 down-3">
          <div className="my-col-11 my-card-2 pdb-10">
            <div className="my-col-10 off-1 xs-container xs-down-3">
              <div className="blog-img-container down-9"><img src={path + i.image} alt="" /></div>
              <div className="my-mother down-3 xs-down-5 monR"><span className="bold px13 alice" >{i.title}</span></div>
              {/* <div className="my-mother down-2">
                <span><i className="fas fa-thumbs-up px13 alice"></i></span>
                <span className="off-1"><i className="fas fa-comments px13 alice pd10"></i></span>
              </div> */}
             <div className="my-mother xs-down-2 down-2 blog-bottom-icon"> <span className="px10 faded-2 monR">{parse(`${i.body.slice(0, 90)}`)}</span></div>
              <div className="my-mother down-3 xs-down-5">
                <span className="px10 faded-2"><i className="fas fa-clock alice"></i> {formatTransactionTime(i?.trn, formattedTime)}</span>
              </div>
              <div className="my-mother down-3 xs-down-5">
                <span onClick={()=> {setEdit(`edit_${i.id}`)}} title="Edit Post"><i className="fas fa-pen px10 faded-1 pd10 c-pointer"></i></span>
                <span className="off-1" onClick={()=> {setDelete(`delete_${i.id}`)}} title="delete Post"><i className="fas fa-trash-alt px10 faded-1 pd10 c-pointer"></i></span>
              </div>
              </div>
          </div>
        </div>
        </>
        ))}
      </div>
    </div>
   </div>
   <div className="my-bottom-50 my-mother"></div>
<div className="my-bottom-50 my-mother"> </div>
{/* <div className="my-bottom-50 hidden-ls xs-12"> </div> */}
  </> );
}
 
export default AdminHome;