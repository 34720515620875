import { AiOutlineClockCircle, AiOutlineCloseCircle, AiOutlineMessage } from "react-icons/ai";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BlogContext } from "../../context/blogcontext";
import {useContext} from 'react';
const Layout = () => {
  const history = useHistory();
  const logout =()=> {
    localStorage.removeItem('fla-admin');
    history.push("/adminlogin")
  }
 const {newMesaage} = useContext(BlogContext)
  return ( <>
  <div className="mobile-nav hidden-ls hidden-xs">
    <div className="xs-container xs-down-2">
      {/* <div className="xs-2"> <div className="my-logo"></div></div> */}
      <div className="my-col-3 xs-8 xs-off-2"><input type="text" placeholder="Search Posts" className="search-input bg-faded" /></div>
       <div className="my-col-1 xs-2 xs-down-3"><i className="pd10 px13 alice fas fa-search my-btn-sm"></i></div>
    </div>
  </div>
  <div className="sidebar">
    <div className="my-col-10 off-1 xs-10 xs-off-1 down-15">
      <div className="my-col-12 hidden-xs"><div className="my-logo"></div></div>
      <div className="adlinks monR down-50" title="Close Menu" onClick={() => {history.push('/admin/dashboard')}}>
          <span className="px12 monR"> <i className="fas fa-bars "></i></span>
          {/* <span className="circleactive"></span> */}
        </div>
      <div className="my-mother down-er xs-top-20">
        <div className="adlinks monR"  title="All Products" onClick={() => {history.push('/admin/dashboard')}}>
          <span className="px12 monR"> <i className="fas fa-house"></i></span>
          {/* <span className="circleactive"></span> */}
        </div>
        <div className="adlinks monR hidden-ls" title="Products" onClick={() => {history.push('/admin/properties')}}>
          <span className=" monR"> <i className="fas fa-plus px13"></i> </span>
        </div>
        <div className="adlinks monR"  title="All Blog" onClick={() => {history.push('/admin/blogs')}}>
          <span className="px12 monR"> <i className="fas fa-blog "></i></span>
          {/* <span className="circleactive"></span> */}
        </div>
        <div className="adlinks monR hidden-ls" title="Add New Blog"  onClick={() => {history.push('/admin/newpost')}}>
          <span className="px12 monR"><i className="fas fa-pen  "></i> </span>
        </div>
        <div className="adlinks monR" title="Messages"  onClick={() => {history.push('/admin/messages')}}>
          <span className=" monR"> <i className="fas fa-comment px13"></i> </span>
          {newMesaage?.length > 0 && <sup className="px8 bold"> <span className="pin-notify">New</span></sup>}
        </div>
        <div className="adlinks monR" title="Referers" onClick={() => {history.push('/admin/agents')}}>
          <span className=" monR"> <i className="fas fa-users px13"></i> </span>
        </div>
        <div className="adlinks monR" title="Logout" onClick={logout}>
          <span className=" monR"> <i className="fas fa-sign-out px13"></i> </span>
        </div>
      </div>
    </div>
  </div>
  <span className="opa1 hidden-xs square-deco"></span>
     <span className="opa1 hidden-xs square-deco1"></span>
     <span className="opa1 hidden-xs square-deco2"></span>
     <span className="opa1 hidden-xs square-deco4 opa1 hidden-xs"></span>
     <span className="opa1 hidden-xs square-deco3"></span>

 <div className="my-mother xs-12  bottom-nav centered hidden-ls hidden-xs">
  <div className="xs-12">
   <div className="xs-3 bgrad alice pd10" onClick={() => {history.push('/admin/dashboard')}}>
    <span className="fas fa-blog"></span>
    <div className="px9"><span>All Posts</span></div>
  </div>
   <div className="xs-3 bgrad alice pd10" onClick={() => {history.push('/admin/newpost')}}>
    <span className="fas fa-add"></span>
    <div className="px9"><span>New Posts</span></div>
  </div>
   <div className="xs-3 bgrad alice pd10" onClick={() => {history.push('/admin/properties')}}>
    <span className="fas fa-house"></span>
    <div className="px9"><span>Properties</span></div>
  </div>
   <div className="xs-3 bgrad alice pd10"  onClick={logout}>
    <span className="fas fa-sign-out"></span>
    <div className="px9"><span>Log Out</span></div>
  </div>
  </div>
</div>
  
  </> );
}
 
export default Layout;