import Nav from "../components/nav";
import deo from "../videos/pexels-david-mcbee-4770380-1920x1080-30fps.mp4"
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import aaj from "../images/aaj.jpg"
import {AiOutlineArrowDown, AiOutlineHeatMap, AiOutlineLock, AiOutlinePhone} from 'react-icons/ai'
import OurOffers from "../components/ouroffers";
import Layout from "../components/layout";
import Fade from  'react-reveal/Fade'
import SiteLayout from "../components/sitelayout";
import Footer from "../components/footer";
import useUtils from "../utils/useutiles";
import NewNav from "../components/newNav";

const About = () => {
 const history = useHistory(0)
 const divRef = useRef(null);
 const vidRef = useRef(null);
 const {openModal} = useUtils();
 const [currentTime, setCurrentTime] = useState(0);

 
const handleClick = () => {
 divRef.current.scrollIntoView({ behavior: 'smooth' });
};

const calendry =()=> {
 window.open('https://calendly.com/adesinajohn/30min')
}

return ( <>

   <NewNav active='about'/>
   <div className="my-mother">
   <Fade bottom>
    <div className="my-mother xs-down-10">
     <div className="my-col-12 xs-10 xs-off-1 xs- xs-down-9vh down-5">
      <div className="my-col-10 off-1 xs-12 xs-down-3 down-6">
       <div className="my-mother px60 alice xs-px40 bold xs-down-2"> <span className="">About</span>  <span>Us <span className="white"></span></span></div>
        <div className="my-mother xs-down- top-1"><span className="px20 faded-2 xs-px13"> Explore more about us </span></div>
     </div>
     </div>
    </div>  
    </Fade>
   </div>
   <div className="my-bottom-50 my-mother down-3">
    <div className="my-col-10 off-1 xs-10 xs-off-1 xs-down-10 top-8 my-bottom-50">
    <Fade bottom>
      <div className="my-col-8 down-8 xs-12">
        <div className="my-mother down-3 faded-1 xs-down-1 bold"><span className="px50 alice xs-px30">Discover the essence of innovation and excellence</span></div>
       <div className="my-mother down-5 xs-down-6 justify">
        <span className="faded-2 xs-px13">
          We are ARBMIDAS Integrated Services Limited, your gateway to a world of cutting-edge opportunities in the realms of real-estates, crypto and NFTs. Established with a vision to redefine possibilities, ARBMIDAS stands as a beacon of innovation in the ever-evolving landscape of digital finance and property investments.
        </span>
         <div className="my-mother down-2 xs-down-3 ">
         <span className="xs-px13 faded-2">
         Rest assured, ARBMIDAS Integrated Services Limited is a registered entity under the Corporate Affairs Commission (CAC), aligning our operations with legal standards and ensuring a secure environment for our clients. Our corporate headquarters, nestled in the vibrant city of Ibadan, serves as the epicenter of our operations and innovation.
         </span>
         </div>
       </div>
        <div className="my-mother down-5 xs-down-8 xs-centered"><span onClick={()=> {history.push('/contact')}} className="xs-12 n-bg-color-code-1 white rad-10 new-cta-1">Speak With Us</span></div>
      </div>
      </Fade>
      <div className="my-col-3 off-1 down-12 opa hidden-xs opa1 bg-white">
        <i className="fas fa-africa"></i>
        <div className="afica"></div>
      </div>
    </div>
   </div>
 <div className="my-mother my-bottom-50 justify">
  <div className="my-col-10 off-1 xs-10 xs-off-1 xs-top-10" ref={divRef}>
 <div className="my-mother">
  <div className="my-mother xs-down-1"><span className=" bold px30 xs-px20 alice">Our Diverse Portfolio</span></div>
  <div className="my-mother down-2 xs-down-5">
   <span className="faded-2 xs-px13"> At ARBMIDAS, we pride ourselves on our multifaceted services, ranging from the issuance of our bespoke crypto token to the creation and trading of Non-Fungible Tokens (NFTs). Our commitment extends beyond the digital sphere, as we actively engage in real estate ventures, providing you with a holistic platform to explore and diversify your investments.</span>
  </div>
 </div> 
  </div>
 </div>


 <div className="my-mother my-bottom-50 justify">
  <div className="my-col-10 off-1 xs-10 xs-off-1" ref={divRef}>
 <div className="my-mother">
  <div className="my-mother xs-down-1"><span className=" bold px20 xs-px20 alice">Real Estate Ventures</span></div>
  <div className="my-mother down-2 faded-1 xs-down-5">
   <span className="faded-2 xs-px13">
     Diversification is key to a robust investment strategy, and ARBMIDAS recognizes the timeless value of real estate. With a focus on transparency and integrity, our real estate ventures present lucrative opportunities for investors seeking stability and growth</span>
    </div>
   </div> 
  </div>
 </div>


 <div className="my-mother my-bottom-50 justify">
  <div className="my-col-10 off-1 xs-10 xs-off-1" ref={divRef}>
 <div className="my-mother">
  <div className="my-mother xs-down-1"><span className=" bold px20 xs-px20 alice">NFT Innovation</span></div>
  <div className="my-mother down-3 faded-1 xs-down-5">
   <span className="faded-2 xs-px13">
     Embrace the future of digital ownership with our NFT offerings. ARBMIDAS is at the forefront of the NFT revolution, providing a platform for artists, collectors, and enthusiasts to connect in a decentralized marketplace. Our NFT ecosystem is a testament to our commitment to fostering creativity and individual expression in the digital realm.
   </span>
    </div>
   </div> 
  </div>
 </div>


 <div className="my-mother my-bottom-50 justify">
  <div className="my-col-10 off-1 xs-10 xs-off-1" ref={divRef}>
 <div className="my-mother">
  <div className="my-mother xs-down-1"><span className=" bold px20 xs-px20 alice">Crypto Token Expertise</span></div>
  <div className="my-mother down-2 faded-1 xs-down-5">
    <span className="faded-2 xs-px13">
    In the dynamic world of cryptocurrency, ARBMIDAS Integrated Services Limited takes the lead. Our proprietary crypto token <a href="" className="new-color-code-2">(ardmidas token)</a> reflects our dedication to pioneering financial solutions, offering you a secure and efficient avenue to participate in the digital economy. Whether you're an experienced trader or a newcomer to the crypto space, our token opens doors to unparalleled possibilities.
    </span>
    </div>
   </div> 
  </div>
 </div>






 <div className='my-mother my-bottom-50 centere bg-black  justify'>
    <div className='my-col-10 xs-10 xs-off-1 xs-down-10 down-5 off-1 '>
      <div className='my-col-12 xs-12  '>
        <div><span className='px30 xs-px20 alice bold'>Our Commitment</span></div>
        <div className='my-mother down-3 xs-down-5'>
          <span className='faded-2 xs-px13'>As we navigate the intricate landscapes of crypto, NFTs, and real estate, ARBMIDAS is unwavering in its commitment to excellence, transparency, and customer satisfaction. Join us on this journey of financial empowerment and investment brilliance. Discover a world where the possibilities are limitless, and the future is yours to shape with ARBMIDAS Integrated Services Limited.</span>
        </div>
        <div className="my-mother down-5 xs-down-8 xs-centered"><span onClick={()=> {history.push('/contact')}} className="xs-12 n-bg-color-code-1 white rad-10 new-cta-1">Speak With Us</span></div>
      </div>
    </div>
  </div>

   <div className="my-mother my-bottom-50 bg-black">
    <div className="my-col-10 off-1 down-5 xs-10 xs-off-1 xs-down-10">
      <div><span className='px30 alice bold'>Meet Our Team</span></div>
      <div className="my-mother"><span className="xs-px13 faded-2"> We run a team of people with great minds</span></div>

      <div className="my-mother down-3 xs-down-10">
        <div className="my-col-4 xs-12 xs-down-10">
         <div className="my-col-11 xs-12">
           <div className="img-container-2"><img src="https://arbmidas.com/wp-content/uploads/2023/05/gbrodowski_480x300.png" alt="" /></div>
          <div className="my-mother xs-down-10 centered down-5 px13 bold xs-px13 alice"><span>GRZEGORZ BRODOWSKI</span></div>
          <div className="my-mother centered xs-down-2 down-2 px10 bold monR xs-px13 faded-2"><span>FOUNDER & CEO</span></div>
         </div>
        </div>
        <div className="my-col-4 xs-12 xs-down-10">
         <div className="my-col-11 xs-12">
           <div className="img-container-2"><img src="https://arbmidas.com/wp-content/uploads/2023/05/rgorniak_480x300_b.png" alt="" /></div>
          <div className="my-mother xs-down-10 centered down-5 px13 bold xs-px13 alice"><span>RAFAŁ GÓRNIAK</span></div>
          <div className="my-mother centered xs-down-2 down-2 px10 bold monR xs-px13 faded-2"><span>CO-FUNDER</span></div>
         </div>
        </div>
        <div className="my-col-4 xs-12 xs-down-10">
         <div className="my-col-11 xs-12">
           <div className="img-container-2"><img src="https://arbmidas.com/wp-content/uploads/2023/05/oaadebisi_480x300_c.png" alt="" /></div>
          <div className="my-mother xs-down-10 centered down-5 px13 bold xs-px13 alice"><span>OLUWATOBILOBA ADEOLA ADEBISI</span></div>
          <div className="my-mother centered xs-down-2 down-2 px10 bold monR xs-px13 faded-2"><span>COMMUNITY MANAGER</span></div>
         </div>
        </div>
      </div>



    </div>
   </div>



<Footer/>

 </> );
}
 
export default About;