import axios from 'axios';
import {useLayoutEffect} from 'react';


const useUtils = () => {

 const  closeModal =(dom)=> {
  document.getElementById(dom).style.display = 'none'
 }

 const  openModal =(dom)=> {
  document.getElementById(dom).style.display = 'block'
 }

 const isSending =(param)=> {
  var loader = document.getElementById('my-loader')
  if(param === true){
   loader.classList.add('d-block');
  }else{
   loader.classList.remove('d-block');
  }
 }

 const requestMaker = async (api, params) => {
 	const form = new FormData();
  const key = Object.keys(params);
  const value = Object.values(params);
  for(var i = 0; i < key.length; i++){
   form.append(key[i], value[i]);
  }
  const request = {
    method: 'post',  
    url: api, 
    headers: { 'Content-Type': 'multipart/form-data'}, 
    data: form,
  }  
  try {
   isSending(true);
    const status = await axios(request);
    return status.data;
   } catch (error) {
    isSending(false)
    alert('server or network error. Please try again later');
  }
}


let toastTimeout; // variable to hold the timeout

const setToast = (msg, iconType) => {
  const toast = document.getElementById('my-toast');
  const mg = document.getElementById('msg');
  const icon = document.getElementById('toast-icon');
  if (msg !== "") {
    mg.innerHTML = msg;
    toast.className = 'my-toast showtoast';
    // icon.className = iconType;
    clearTimeout(toastTimeout); // clear any existing timeout
    toastTimeout = setTimeout(() => {
      toast.className = 'my-toast hidetoast';
    }, 2000);
  }
}

useLayoutEffect(()=> {
 const myToast = document.getElementById('my-toast');
myToast.addEventListener('mouseover', function() {
  clearTimeout(toastTimeout); // clear the timeout when mouseover
  this.className = 'my-toast showtoast';
});

myToast.addEventListener('mouseout', function() {
  const toast = this;
  toastTimeout = setTimeout(() => {
    toast.className = 'my-toast hidetoast';
  }, 2000);
});
})

function formatTransactionTime(transactionTime, currentTime) {
  const diffInMs = Math.abs(new Date(currentTime) - new Date(transactionTime));
  const diffInMins = Math.round(diffInMs / (1000 * 60));
  const transactionDate = new Date(transactionTime);
  if (diffInMins < 1) {
    return "just now";
  } else if (diffInMins < 1440 && transactionDate.getDate() === new Date(currentTime).getDate()) {
    const hours = transactionDate.getHours();
    const minutes = transactionDate.getMinutes().toString().padStart(2, "0");
    const time = convertTo12HourFormat(hours, minutes);
    return `Today, ${time}`;
  } else if (diffInMins < 2880) {
    const hours = transactionDate.getHours();
    const minutes = transactionDate.getMinutes().toString().padStart(2, "0");
    const time = convertTo12HourFormat(hours, minutes);
    return `Yesterday, ${time}`;
  } else {
    const month = transactionDate.toLocaleString('default', { month: 'long' });
    const day = transactionDate.getDate().toString().padStart(2, "0");
    const year = transactionDate.getFullYear().toString();
    const hours = transactionDate.getHours();
    const minutes = transactionDate.getMinutes().toString().padStart(2, "0");
    const time = convertTo12HourFormat(hours, minutes);
    return `${month} ${day}, ${year} at ${time}`;
  }
 }
 
 function convertTo12HourFormat(hours, minutes) {
  const period = hours >= 12 ? "PM" : "AM";
  const hour12 = hours % 12 || 12;
  return `${hour12}:${minutes} ${period}`;
 }

 return {
  closeModal, 
  openModal,
  isSending,
  setToast,
  requestMaker,
  formatTransactionTime

 };
}
 
export default useUtils;