import { useHistory } from "react-router-dom";

const NotFound = () => {
 const history = useHistory()
 return ( <>
  <div className="my-col-10 off-2 xs-10 xs-off-1 down-10 px50 white bold bg-black xs-down-15vh">
   <div><span>Error 404</span></div>
   <div className="mother"><span>Oops!, Page not found.</span></div>
   <div className="mother"><span className="px13 btn-sm-2 bg-faded white" onClick={()=> {history.push('/')}}>Back to home page</span></div>
  </div>
 </> );
}
export default NotFound;