import Layout from "./layout";
import {Editor} from '@tinymce/tinymce-react';
import { useContext, useEffect, useRef } from "react";
import {useState} from 'react';
import { BlogContext } from "../../context/blogcontext";
import useUtils from "../../utils/useutiles";
import useApi from "../../hooks/useApi";

const EditProduct = ({i, setEdit}) => {
  const {setToast} = useUtils();
  const {requestMaker} = useApi();
 const editorRef = useRef(); 
 useEffect(() => {
  document.body.style.backgroundColor = '#000';
 })
   
 const {token, getProducts, path} = useContext(BlogContext);
 const [preview, setPreview] = useState( path + i?.media);
 const [isLiving, setIsLiving] = useState(false);
 const [filetype, setFileType] = useState(i.media_type);
 const [newpost, setNewPost] = useState({
  title:i?.title,
  sub_title:i?.sub_title,
  body:i?.bold,
  media:i?.media,
  media_type:i?.media_type,
  old_image:i?.media,
  token:token,
  id:i?.id,
  action:"update_post"
 })
 const clickHandler =(id)=> {
  if(id){
    document.getElementById(id).click();
  }
 }

 const handleImageChange = async (event) => {
  const file = event.target.files[0];
  let url;

  if (file) {
    if (
      (file.type === 'image/jpeg' ||
        file.type === 'image/jpg' ||
        file.type === 'image/png') &&
       file.size < 4 * 1024 * 1024
    ) {
      setFileType('Image')
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;

        img.onload = () => {
          const canvas = document.createElement('canvas');
          const MAX_WIDTH = 400;
          const MAX_HEIGHT = 400;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
          const dataURL = canvas.toDataURL(file.type);
          setPreview(dataURL);
          url = dataURL;
        };
      };
      setNewPost((prev) => ({ ...prev, media: file, media_type:'Image'}));
    } else if (
      (file.type === 'video/mp4' ||
        file.type === 'video/mpeg' ||
        file.type === 'video/quicktime') &&
        file.size < 4 * 1024 * 1024
    ) {
      setFileType('Video')
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        url = reader.result;
        setPreview(url);
      };
      setNewPost((prev) => ({...prev, media:file, media_type:'Video' }));
    } else {
      setToast(
        'Please select an image (JPG, JPEG, PNG) less than 4MB or a video (MP4, MPEG, QuickTime) less than 4MB in size'
      );
    }
  } else {
    setToast('Please select a valid file.');
  }
};

  const submitPost = async () => {
    const body = editorRef.current.getContent();
    if(newpost?.title === ""){
      setToast("Please enter a title for your Product");
      return
    }
    if(newpost?.image === ""){
      setToast("Please enter an image/Video for your Product");
      return
    }
    if(body === ""){
      setToast("you have'nt typed anything");
      return
    }
    newpost['body'] = body;
    setIsLiving(true);
    const res = await requestMaker('/product/producthandler', newpost)
    if(res?.status  === 'done'){
     await getProducts(token)
     setToast("Your Product updated live");
     setIsLiving(false)
     setEdit(null)
    }else {
      setIsLiving(false)
      setToast('There was an error publishing this Product')
    }

  }



  return ( <>
   {/* <Layout/> */}
   <div className="full-overide-modal xs-10 xs-off-1">
    <div className="my-col-10 xs-11 xs-down-10 off-1 down-10">
     <div className="my-mother"> <span className="alice bold"> <i onClick={()=> {setEdit(null)}} className="pd10 fas fa-arrow-left alice c-pointer px13"></i>  Edit Post</span></div>
     <div className="my-mother down-1">
      <div className="my-col-4"><input type="text" value={newpost?.title} onChange={(e)=> {setNewPost(prev => ({...prev, title:e.target.value}))}} placeholder="Post Title" className="search-input bg-faded" /></div>
       <div className="my-col-8 right hidden-xs">
        {isLiving? <span className="btn-sm-2 px10 monR bold alice bgrad">Publishing...</span>:
        <span className="btn-sm-2 px10 monR bold alice bgrad" onClick={submitPost}>Update Post</span>}
        
        {/* <span className="btn-sm-2 px10 monR bold alice bgrad off-1">Save to Draft</span> */}
       </div>
     </div>
      <div className="my-mother down-2">
      <div className="my-col-4"><input type="text" value={newpost?.sub_title} onChange={(e)=> {setNewPost(prev => ({...prev, sub_title:e.target.value}))}} placeholder="Post sub title (optional)" className="search-input bg-faded" /></div>
       
      </div>
     <div className="my-mother down-2 xs-down-10">
       <div className="my-col-8">
       {/* <textarea className="textarea area-h" id="" cols="30" rows="10"></textarea> */}
       <Editor
        onInit={(evt, editor) => editorRef.current = editor }
        initialValue={i?.body}
        init={
          {
            // menubar:false,
            height:250
          }
        }
       />
       </div>
       <div className="my-col-4">
        <div className="my-col-10 off-1">
       <div className="my-mother down-2">
        <div><span className="px10 alice">Product File</span></div>
        <input type="file" accept=".jpg,.jpeg,.png,.mp4,.mpeg,.mp3.quicktime" id="upload-pp_3" hidden onChange={(e) => {handleImageChange(e)}} />
        <div className="my-col-12"><div className="blog-img-container" onClick={()=> {clickHandler('upload-pp_3')}} >
          {filetype === 'Image'?  <img src={preview} alt="" /> :  <video src={preview} alt="" />}
          </div>
          </div>
          </div>
        </div>
       </div>
       <div className="xs-12 xs-down-5 hidden-ls">
        {isLiving? <span className="btn-sm-2 px10 monR bold alice bgrad">Publishing...</span>:
        <span className="btn-sm-2 px10 monR bold alice bgrad" onClick={submitPost}>Update Post</span>}
       </div>
     </div>
    </div>
   </div>
  </> );
}
 
export default EditProduct;