import {createContext, useEffect, useState} from 'react';
import useApi from '../hooks/useApi';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


export const AuthContext = createContext();

const AuthContextProvider = (props) => {
 const token = localStorage.getItem('fla-admin');
 const {requestMaker} = useApi();
 const [currentUser, setCurrentUsers] = useState(null)
 const history = useHistory();
 
 const logoutOut = () => {
  localStorage.removeItem('fla-admin');
  setCurrentUsers(null);
  history.push('/adminlogin')
 }

 const getUser = async(Token) => {
  const params = {token:Token}
  const result = await requestMaker('blog/current', params);
  if(result?.status === 'done'){
   setCurrentUsers(result?.data);
  } if(result?.status === 'expired'){
    logoutOut()
  }
 }

   
 return ( 
  <AuthContext.Provider value={{
   getUser,
   currentUser,
   logoutOut,
   token
  }}>
   {props.children}
  </AuthContext.Provider>
 );
}
 
export default AuthContextProvider;
